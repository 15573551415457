export default {
  postAuthLogin(payload) {
    return this.baseApi.post('/auth/login', payload);
  },
  postAuthLogout() {
    return this.baseApi.post('/auth/logout');
  },
  postRegistration(payload) {
    return this.baseApi.post('/auth/registration', payload);
  },
  postVerifyEmail(payload) {
    return this.baseApi.post('/auth/verify-email', payload);
  },
  postDemoRegistration(payload) {
    return this.baseApi.post('/auth/demo/registration', payload);
  },
  postDemoVerify(payload) {
    return this.baseApi.post('/auth/demo/verify', payload);
  },
};
