import ElementUI from './element-ui';
import Notifications from 'vue-notification';
import HighchartsVue from 'highcharts-vue';
import VueLazyload from 'vue-lazyload';

export default (App) => {
  App.use(ElementUI);
  App.use(Notifications);
  App.use(HighchartsVue);
  App.use(VueLazyload);
};
