import axios from 'axios';
import QS from 'qs';
import { defaultLanguage } from '@/i18n';

export default {
  baseApi(baseUrl) {
    return axios.create({
      baseURL: baseUrl,
      // withCredentials: true,
      headers: {
        common: {
          Authorization: localStorage.getItem('auth-token')
            ? `Bearer ${localStorage.getItem('auth-token')}`
            : '',
          'x-language': defaultLanguage,
        },
      },
      paramsSerializer(params) {
        return QS.stringify(params, { arrayFormat: 'repeat' });
      },
      timeout: 50000,
    });
  },
};
