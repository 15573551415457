<template>
  <div class="ui-title" @click="clickHandler">
    <div class="ui-title__wrapper">
      <UiIcon
        v-if="backRoute !== ''"
        :size="16"
        name="chevron-left"
        lib="fa"
        substyle="fas"
        class="mr-sm"
      />
      <div class="ui-title__text">
        <slot />
      </div>
    </div>
    <slot name="status"></slot>
  </div>
</template>

<script>
export default {
  name: 'UiTitle',

  props: {
    backRoute: {
      type: String,
      default() {
        return '';
      },
    },
  },

  methods: {
    clickHandler(event) {
      if (this.backRoute) {
        this.$router.push(this.backRoute);
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
.ui-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  padding: 12px 16px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--color-background-fon);
  z-index: 1;
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__text {
    font-size: 20px;
    line-height: 1.2;
    color: var(--color-text);
  }
}
</style>
