<template>
  <div v-if="isLoading" class="site-loader">
    <UiLogo />
    <Spinner />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isLoading: {
      type: Boolean,
      require: true,
      default() {
        return true;
      },
    },
  },
  watch: {
    isLoading(value) {
      const html = document.querySelector('html');
      if (value) {
        html.classList.add('html_popup');
      } else {
        html.classList.remove('html_popup');
      }
    },
  },
};
</script>

<style lang="scss">
.site-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #303634;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    max-width: 150px;
    margin: 0 auto 12px;
  }
}
</style>
