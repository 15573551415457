<template>
  <div class="login">
    <UiInput
      v-model="form.email"
      class="mb-md"
      :label="$t('auth.login.form.email.label')"
      :placeholder="$t('auth.login.form.email.placeholder')"
      type="text"
      :error="errors.email"
      autocomplete="off"
    />
    <UiInput
      v-model="form.password"
      class="mb-md"
      placeholder="********"
      :label="$t('auth.login.form.password.label')"
      type="password"
      name="password"
      :error="errors.password"
      autocomplete="off"
    />
    <UiCheckbox
      :label="$t('auth.login.form.rememberMe')"
      class="mb-md"
      value="remember_me"
      v-model="form.rememberMe"
    />
    <UiButton
      class="mb-lg"
      :loading="loading"
      :disabled="isDisabled"
      @click="login"
    >
      {{ $t('buttons.login') }}
    </UiButton>
    <div class="login__link">
      <div class="login__link-text">
        {{ $t('auth.login.account.text') }}
      </div>
      <router-link to="signup" class="login__link-button">
        {{ $t('auth.login.account.button') }}
        <UiIcon
          :size="18"
          name="user-plus"
          :color="$config.cpRaw.placeholder"
          lib="fa"
          substyle="far"
        />
      </router-link>
    </div>
    <div class="login__link">
      <div class="login__link-text">
        {{ $t('auth.login.demo.text') }}
      </div>
      <router-link to="demo" class="login__link-button">
        {{ $t('auth.login.demo.button') }}
        <UiIcon
          :size="18"
          name="dashboard"
          :color="$config.cpRaw.placeholder"
          lib="fa"
          substyle="far"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import fingerprint from '@/helpers/fingerprintMixin';

export default {
  name: 'Login',

  mixins: [fingerprint],

  data() {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: true,
      },
      loading: false,
      isValid: false,
      errors: {},
    };
  },
  computed: {
    isDisabled() {
      return this.form.email === '' || this.form.password === '';
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');
    const type = urlParams.get('type');
    if (hash) {
      this.verify(hash, type);
    }
  },
  methods: {
    async verify(hash, type) {
      this.errors = {};
      this.loading = true;
      try {
        const fingerprint = await this.getFingerprint();
        let response = '';
        if (type !== 'demo') {
          response = await this.$api.postVerifyEmail({
            hash: hash,
            fingerprint: fingerprint,
          });
        } else {
          response = await this.$api.postDemoVerify({
            hash: hash,
            fingerprint: fingerprint,
          });
        }
        await this.$store.dispatch('auth/authRequest', {
          payload: response.data.payload,
        });
        await this.$router.push('/dashboard');
      } catch (errors) {
        this.loading = false;
        this.errors = errors.data;
        if (this.errors.message) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: this.errors.message,
          });
        }
        if (this.errors?.errors?.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: this.errors.captcha_text[0],
          });
        }
      }
    },
    async login() {
      this.errors = {};
      this.loading = true;
      try {
        const fingerprint = await this.getFingerprint();
        const recaptchaParams = await this.$recaptchaChallange();
        const response = await this.$api.postAuthLogin({
          ...this.form,
          ...recaptchaParams,
          fingerprint: fingerprint,
        });
        if (response.data.payload.user.is_email_verified) {
          await this.$store.dispatch('auth/authRequest', {
            payload: response.data.payload,
            remember: this.form.rememberMe,
          });
          await this.$router.push('/dashboard');
        } else {
          await this.$router.push('/auth/verify');
        }
      } catch (errors) {
        console.error(errors);
        this.errors = errors.data.errors;
        if (this.errors.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: this.errors.captcha_text[0],
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  &__link {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: var(--color-text);
    &:last-child {
      margin-bottom: 0;
    }
    &-text {
      margin-bottom: 8px;
    }
    &-button {
      color: var(--color-text);
      padding: 12px 16px;
      border-radius: 8px;
      background-color: var(--color-background-fon);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
