// get interceptors func
import { EventBusInstance } from '@/setup/eventBus';

export default {
  requestSuccessInterceptor(config) {
    return config;
  },
  requestErrorInterceptor(error) {
    return Promise.reject(error);
  },
  responseSuccessInterceptor(response) {
    return response;
  },
  responseErrorInterceptor(error) {
    const ignoreIntercetors = error.config?.ignoreIntercetors || {};
    try {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            EventBusInstance.$emit('logout-event');
            break;
          case 403:
            if (!(ignoreIntercetors[403] || ignoreIntercetors.all)) {
              EventBusInstance.$emit('logout-event');
            }
            break;
          case 422:
            if (!(ignoreIntercetors[422] || ignoreIntercetors.all)) {
              if (error?.response?.data?.errors['maintenance']) {
                EventBusInstance.$emit('notify-event', {
                  type: 'error',
                  text: error.response.data.message,
                });
              }
            }
            break;
        }
      }
    } catch (e) {
      console.error('connection error', e);
    }
    return Promise.reject(error.response);
  },
};
