import AuthLayout from '@/components/layout/AuthLayout';
import Login from '@/views/Auth/Login';
import Signup from '@/views/Auth/Signup';
import Verify from '@/views/Auth/Verify';
import Demo from '@/views/Auth/Demo';
import multiguard from 'vue-router-multiguard';
import authGuard from '@/router/guards/authGuard';

export default [
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    beforeEnter: multiguard([authGuard(false, '/dashboard')]),
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'auth.login.title',
          source: 'login',
        },
      },
      {
        path: 'verify',
        name: 'Verify',
        component: Verify,
        meta: {
          title: 'auth.verify.title',
          source: 'verify',
        },
      },
      {
        path: 'demo',
        name: 'Demo',
        component: Demo,
        meta: {
          title: 'auth.demo.title',
          source: 'demo',
        },
      },
      {
        path: 'signup',
        name: 'Signup',
        component: Signup,
        meta: {
          title: 'auth.signup.title',
          source: 'signup',
        },
      },
      {
        path: 'request-demo',
        name: 'Signup',
        component: Signup,
        meta: {
          title: 'auth.signup.requestDemo',
          source: 'request-demo',
        },
      },
      {
        path: '',
        redirect: 'login',
      },
      {
        path: '*',
        redirect: 'login',
      },
    ],
  },
];
