<template>
  <div class="ui-tab">
    <div
      v-for="item in items"
      :key="item.label"
      class="ui-tab__item"
      :class="{ 'ui-tab__item_active': currentTab === item.value }"
      @click="handleClick(item.value)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTab',
  props: {
    currentTab: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(value) {
      if (this.currentTab !== value) {
        this.$emit('update:currentTab', value);
      }
    },
  },
};
</script>

<style lang="scss">
.ui-tab {
  margin-bottom: 16px;
  display: flex;
  border-radius: 8px;
  border: 1px solid var(--color-primary);
  overflow: hidden;
  &__item {
    width: 33.3333%;
    text-align: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text);
    border-right: 1px solid var(--color-primary);
    &:last-child {
      border-right: none;
    }
    &_active {
      background-color: var(--color-primary);
    }
  }
}
</style>
