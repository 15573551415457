import store from '@/store';

import numeral from 'numeral';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default (App) => {
  App.prototype.$dayjs.extend(utc);
  App.prototype.$dayjs.extend(timezone);

  // App.filter('time', (value) =>
  //   App.prototype
  //     .$dayjs(value)
  //     .tz(store.getters['settings/currentTimezone'])
  //     .format('HH:mm')
  // );
  // App.filter('date', (value) =>
  //   App.prototype
  //     .$dayjs(value)
  //     .tz(store.getters['settings/currentTimezone'])
  //     .format('D MMM')
  // );
  // App.filter('timeNoTZ', (value) =>
  //   App.prototype.$dayjs(value).format('HH:mm')
  // );
  // App.filter('formatDate', (value) =>
  //   App.prototype.$dayjs(value).format('DD MMM YYYY')
  // );
  App.filter('formatDateTime', (value) =>
    App.prototype
      .$dayjs(value)
      .tz(store.getters['settings/currentTimezone'])
      .format('DD MMM YYYY HH:mm')
  );

  App.filter('formatMoney', (value) => numeral(value).format('0,0.00'));
  // App.filter('formatZero', (value) => numeral(value).format('0.[00]'));
  App.filter('formatNoDot', (value) => numeral(value).format('0,0'));
  App.filter('formatMoneyNoDotNoRound', (value) =>
    numeral(value).format('0,0', Math.floor)
  );
  //
  App.filter(
    'formatPercent',
    (value) => Math.abs(numeral(value).format('0.[00]')) + '%'
  );

  App.filter('dictionary', (value, dictionary, field = 'name') => {
    const index = store.getters[`misc/${dictionary}Index`];
    return index && index[value] ? index[value][field] : '';
  });
};
