import { VueReCaptcha } from 'vue-recaptcha-v3';
import Vue from 'vue';
import store from '@/store';

const state = {
  currentTimezone: 'UTC',
  settings: null,
};

const getters = {
  currentTimezone: (state) => state.currentTimezone,
  settings: (state) => state.settings,
};

const actions = {
  async settingsValues({ commit, dispatch, state }) {
    try {
      const response = await store.$api.getSettings();
      const settings = response.data.payload;
      if (settings.recaptcha_site_key !== state.settings?.recaptcha_site_key) {
        dispatch('settingsLoadRecaptcha', settings);
      }
      commit('settingsValues', settings);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  },
  settingsLoadRecaptcha(store, settings) {
    VueReCaptcha(Vue, {
      siteKey: settings.recaptcha_site_key,
    });
    if (!Vue.prototype.$recaptchaChallange) {
      Vue.prototype.$recaptchaChallange = async (captchaType = 'recaptcha') => {
        const { recaptcha, recaptchaID } = Vue.prototype.$recaptchaInstance;
        const status = await Vue.prototype.$recaptchaLoaded();
        if (status) {
          const token = await new Promise((resolve) => {
            recaptcha.ready(async () => {
              const result = await recaptcha.execute();
              resolve(result);
            });
          });

          return {
            captcha_type: captchaType,
            captcha_id: recaptchaID + '',
            captcha_text: token,
          };
        }
      };
    }
  },
};

const mutations = {
  settingsValues(state, payload) {
    state.settings = payload;
  },
  settingsTimezones(state, payload) {
    state.currentTimezone = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
