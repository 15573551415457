<template>
  <div class="spinner">
    <div class="spinner__loading" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style scoped lang="scss">
.spinner {
  position: relative;
  width: 22px;
  height: 22px;
  display: inline-block;
  &__loading {
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-radius: 50%;
    z-index: 10;
    &:after,
    &:before {
      box-sizing: border-box;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      border-radius: inherit;
      border-bottom: 2px solid var(--color-primary);
      animation: loadingAnim 0.8s ease infinite;
      top: 0;
      content: '';
    }
    &:before {
      animation: loadingAnim 0.8s linear infinite;
      opacity: 0.2;
    }
  }
}

@keyframes loadingAnim {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
