<template>
  <div v-if="user" class="profile">
    <UiTitle>
      {{ $t('profile.title') }}
      <Status slot="status" />
    </UiTitle>
    <div class="profile__content">
      <div>
        <div v-if="user.cash_id && user.cash_id !== ''" class="profile__id">
          <div class="flex align-middle">
            <UiIcon
              class="mr-sm"
              :size="18"
              name="user"
              :color="$config.cp.placeholder"
              lib="fa"
              substyle="far"
            />
            {{ $t('profile.id', { id: user.cash_id }) }}
          </div>
          <div v-if="!isDemo" @click="copy">
            <UiIcon
              :size="24"
              name="copy"
              :color="$config.cp.alter"
              lib="fa"
              substyle="far"
            />
          </div>
        </div>
        <div class="profile__name">{{ user.full_name }}</div>
        <div class="profile__contact">
          <UiIcon
            class="mr-sm"
            :size="16"
            name="envelope"
            :color="$config.cp.placeholder"
            lib="fa"
            substyle="far"
          />
          {{ user.email }}
        </div>
        <template v-if="!isDemo">
          <div class="profile__contact">
            <UiIcon
              class="mr-sm"
              :size="16"
              name="phone"
              :color="$config.cp.placeholder"
              lib="fa"
              substyle="far"
            />
            +{{ user.phone }}
          </div>
          <div class="profile__contact">
            <img class="profile__contact-ico" :src="messengerIcon" alt="" />
            {{ user.messenger_id }}
          </div>
          <div class="mb-md"></div>
          <div class="profile__address">
            <div class="profile__address-label">
              {{ $t('profile.country') }}
            </div>
            <div class="profile__address-value">{{ pCountry }}</div>
          </div>
          <UiSeparator class="mt-sm mb-md" />
          <div class="profile__address">
            <div class="profile__address-label">{{ $t('profile.city') }}</div>
            <div class="profile__address-value">{{ user.city }}</div>
          </div>
          <UiSeparator class="mt-sm mb-md" />
          <div class="profile__address mb-lg">
            <div class="profile__address-label">{{ $t('profile.street') }}</div>
            <div class="profile__address-value">{{ user.street }}</div>
          </div>
        </template>
      </div>
      <div>
        <a
          href="https://t.me/agentmanbw"
          target="_blank"
          class="profile__button profile__button_support mb-md"
        >
          {{ $t('buttons.supportService') }}
          <UiIcon
            class="mr-sm"
            :size="18"
            name="telegram"
            color="#ffffff"
            lib="fa"
            substyle="fab"
          />
        </a>
        <div class="profile__button profile__button_logout" @click="logout">
          {{ $t('buttons.logout') }}
          <UiIcon
            class="mr-sm"
            :size="18"
            name="sign-out"
            :color="$config.cp.error"
            lib="fa"
            substyle="far"
          />
        </div>
        <UiSelect
          :value="currentLang"
          :list="languages"
          class="mb-md"
          is-item-ico
          is-list-item-ico
          is-icons-dictionary="languagesIndex"
          option-value-param="code"
          option-label-param="name"
          option-name-param="name"
          @change="changeLang"
        />
        <UiSelect2
          :value="computedCurrentTimezone"
          :list="pTimezones"
          option-value-param="code"
          option-label-param="name"
          option-name-param="name"
          @change="changeTimezone"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Profile',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('misc', [
      'languages',
      'messengersIndex',
      'countriesIndex',
      'timezones',
    ]),
    ...mapGetters('settings', ['currentTimezone']),
    ...mapGetters('auth', ['user']),
    isDemo() {
      return this.user?.is_demo;
    },
    pTimezones() {
      const zones = this.$_.cloneDeep(this.timezones);
      return zones.map((item) => {
        item.id = item.zone;
        item.text = item.name;
        return item;
      });
    },
    computedCurrentTimezone() {
      return this.currentTimezone;
    },
    currentLang() {
      return this.$i18n.locale;
    },
    messengerIcon() {
      return this.messengersIndex[this.user.messenger]?.image || '';
    },
    pCountry() {
      return this.countriesIndex[this.user.country_code]?.name || '-';
    },
  },
  methods: {
    changeLang(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
    },
    changeTimezone(value) {
      this.$store.commit('settings/settingsTimezones', value);
      localStorage.setItem('select-timezone', value);
    },
    openPopupHandler(popup) {
      this.$eventBus.$emit('open-popup', popup);
    },
    copy() {
      const t = document.createElement('input');
      t.contentEditable = true;
      t.readOnly = true;
      t.style.position = 'absolute';
      t.style.left = '-9999px';
      document.body.appendChild(t);
      t.value = this.user.cash_id;
      t.select();
      t.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
        this.$eventBus.$emit('notify-event', {
          type: 'success',
          text: this.$t('profile.copied'),
        });
      } catch (e) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: e.message,
        });
      }
      t.remove();
      return false;
    },
    async logout() {
      this.$eventBus.$emit('logout-event');
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  color: var(--color-text);

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    padding: 16px;
  }
  &__id {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: #303634;
  }
  &__name {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
  }
  &__contact {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    &-ico {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      min-width: 16px;
    }
  }
  &__address {
    font-size: 14px;
    line-height: 1.71;
    &-label {
      font-weight: 600;
    }
    &-value {
      font-size: 14px;
    }
  }
  &__button {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    margin-bottom: 12px;
    &_support {
      color: var(--color-text-alter-button);
      background-color: #408ce4;
    }
    &_logout {
      color: var(--color-error);
    }
  }
}
</style>
