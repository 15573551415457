import store from '@/store';
import config from '@/config';

const stateParamsWithIndex = [
  'countries',
  'languages',
  'messengers',
  'timezones',
];

function genStateSourceParams(type) {
  if (type === 'getters') {
    return {
      origin: (key) => (store) => store[key],
      index: (key) => (store) => store[`${key}Index`],
    };
  }

  return {
    origin: () => [],
    index: () => ({}),
  };
}

function genStateWithIndices(params, type) {
  const result = {};
  const source = genStateSourceParams(type);

  params.forEach((key) => {
    result[key] = source.origin(key);
    result[`${key}Index`] = source.index(key);
  });

  return result;
}

const state = {
  ...genStateWithIndices(stateParamsWithIndex, 'state'),
  monthList: null,
  monthListIndex: null,
  isLoading: true,
  locationStatus: 1,
  coords: null,
  distance: 0,
  stopStatusList: ['pending', 'rejected'],
};

const getters = {
  ...genStateWithIndices(stateParamsWithIndex, 'getters'),
  monthList: (store) => store.monthList,
  monthListIndex: (store) => store.monthListIndex,
  isLoading: (store) => store.isLoading,
  locationStatus: (store) => store.locationStatus,
  coords: (store) => store.coords,
  distance: (store) => store.distance,
  stopStatusList: (store) => store.stopStatusList,
};

const actions = {
  async miscInitDictionaries({ commit }, payload) {
    const response = await store.$api.getDictionaries(payload);
    commit('miscInitDictionaries', response.data.payload);
  },
  miscIsLoading({ commit }, payload) {
    commit('miscIsLoading', payload);
  },
  setLocationStatus({ commit }, payload) {
    commit('setLocationStatus', payload);
  },
  setCoords({ commit }, payload) {
    commit('setCoords', payload);
  },
  setDistance({ commit }, payload) {
    commit('setDistance', payload);
  },
};

const mutations = {
  miscInitDictionaries(state, payload) {
    stateParamsWithIndex.forEach((key) => {
      if (key === 'countries') {
        state[key] = payload[key];
        payload[key].forEach((item) => {
          if (!item.image) {
            item.image = config.params.defaultCountryLogo;
          }
        });
      } else {
        state[key] = payload[key];
      }

      const dictionary = {};
      const idField = 'code';
      payload[key].forEach((item, index) => {
        dictionary[item[idField]] = {
          ...item,
          index,
        };
      });
      state[`${key}Index`] = dictionary;
    });
  },
  miscIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setLocationStatus(state, payload) {
    state.locationStatus = payload;
  },
  setCoords(state, payload) {
    state.coords = payload;
  },
  setDistance(state, payload) {
    state.distance = payload;
  },

  initListOfMonth(state, { monthList, monthListIndex }) {
    state.monthList = monthList;
    state.monthListIndex = monthListIndex;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
