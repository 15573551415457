import store from '@/store';
import { CookieStorage } from 'cookie-storage';
import { EventBusInstance } from '@/setup/eventBus';
import i18n from '@/i18n';

const cookieStorage = new CookieStorage();
const persistentStorage = localStorage;
const temporaryStorage = sessionStorage;

const bothStorage = {
  getItem(key) {
    return persistentStorage.getItem(key) || temporaryStorage.getItem(key);
  },
  setItem(key, value) {
    persistentStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
  },
  removeItem(key) {
    persistentStorage.removeItem(key);
    temporaryStorage.removeItem(key);
  },
};
const getStorage = (both = false, onlyPersistent = true) => {
  return both
    ? bothStorage
    : onlyPersistent
    ? persistentStorage
    : persistentStorage.getItem('remember-me') === 'true'
    ? persistentStorage
    : temporaryStorage;
};

if (
  persistentStorage.getItem('remember-me') !== 'true' &&
  cookieStorage.getItem('remember-me') !== 'true'
) {
  const storage = getStorage(true);

  storage.removeItem('auth-token');
}

const state = {
  token: getStorage().getItem('auth-token') || '',
  status: getStorage().getItem('auth-token') ? 'success' : '',
  currencyCode: '',
  user: null,
  rememberMe: null,
};

const getters = {
  isAuthenticated: (state) => state.status === 'success',
  token: (state) => state.token,
  user: (state) => state.user,
  currencyCode: (state) => state.currencyCode,
};

const actions = {
  async authUserSetup({ dispatch, state, commit }, user) {
    if (state.token) {
      try {
        const promise = Promise.all([dispatch('authUser', user)]);
        await promise;
        await dispatch('settings/settingsValues', {}, { root: true });
        return promise[0];
      } catch (error) {
        console.error('invalid token', error);
      }
    } else {
      commit('authLogout');
      dispatch('settings/settingsValues', {}, { root: true });
    }
  },
  async authUser({ commit }, user) {
    let payload = user;
    if (!user) {
      const response = await store.$api.getUserProfile();
      payload = response.data.payload.user;
    }

    await commit('authUser', payload);
    await commit('updateCurrency', payload.channel);
  },

  async updateUser({ commit }) {
    try {
      const response = await store.$api.getUserWalletCheck();
      await commit('updateUser', response.data.payload.user);
    } catch (error) {
      EventBusInstance.$emit('notify-event', {
        type: 'error',
        text: error.data.message,
      });
    }
  },

  async authRequest({ commit, dispatch }, { payload, remember = true }) {
    commit('authRememberMe', remember);

    commit('authSaveToken', payload.token);
    commit('authSuccess');
    dispatch('authUserSetup', payload.user);
  },
  async authLogout({ commit }, clearRequest = false) {
    try {
      if (clearRequest) {
        await store.$api.postAuthLogout();
      }
    } finally {
      await commit('authLogout');
    }
  },
};

const mutations = {
  authSaveToken(state, response) {
    state.token = response.token;
  },
  authSuccess(state) {
    const storage = getStorage(true);

    storage.setItem('auth-token', state.token);

    store.$api.baseApi.defaults.headers.common.Authorization = `Bearer ${state.token}`;
    state.status = 'success';
  },
  authUser(state, payload) {
    state.user = payload;
  },
  updateUser(state, payload) {
    state.user = payload;
    EventBusInstance.$emit('notify-event', {
      type: 'success',
      text: i18n.t('profile.updated'),
    });
  },
  updateCurrency(state, payload) {
    state.currencyCode = payload.currency_code;
  },
  authLogout(state) {
    state.user = null;
    state.status = '';
    state.token = '';
    const storage = getStorage(true);
    storage.removeItem('auth-token');
    state.authStatus = '';
    store.$api.baseApi.defaults.headers.common.Authorization = '';
  },
  authRememberMe(state, rememberMe) {
    state.rememberMe = rememberMe;
    if (rememberMe === null) {
      localStorage.removeItem('remember-me');
      cookieStorage.removeItem('remember-me');
    } else {
      if (rememberMe) {
        localStorage.setItem('remember-me', true);
        cookieStorage.removeItem('remember-me');
      } else {
        localStorage.removeItem('remember-me');
        cookieStorage.setItem('remember-me', true);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
