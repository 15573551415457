<template>
  <div class="signup">
    <div class="signup__subtitle">{{ $t('auth.signup.subtitle') }}</div>
    <UiInput
      v-model="form.first_name"
      class="mb-md"
      :label="$t('auth.signup.form.firstName.label')"
      :placeholder="$t('auth.signup.form.firstName.placeholder')"
      type="text"
      :error="errors.first_name"
      autocomplete="off"
    />
    <UiInput
      v-model="form.last_name"
      class="mb-md"
      :label="$t('auth.signup.form.lastName.label')"
      :placeholder="$t('auth.signup.form.lastName.placeholder')"
      type="text"
      :error="errors.last_name"
      autocomplete="off"
    />
    <UiInput
      v-model="form.email"
      class="mb-md"
      :label="$t('auth.signup.form.email.label')"
      :placeholder="$t('auth.signup.form.email.placeholder')"
      type="text"
      :error="errors.email"
      autocomplete="off"
    />
    <UiInput
      v-model="form.password"
      class="mb-md"
      placeholder="********"
      :label="$t('auth.login.form.password.label')"
      type="password"
      name="password"
      :error="errors.password"
      autocomplete="off"
      :help="$t('auth.login.form.password.help')"
    />
    <UiInput
      v-model="form.password_confirmation"
      class="mb-md"
      placeholder="********"
      :label="$t('auth.login.form.passwordRepeat.label')"
      type="password"
      name="password"
      :error="errors.password_confirmation"
      autocomplete="off"
    />
    <UiSelect
      class="mb-md"
      :value.sync="form.country_code"
      :list="countries"
      option-value-param="code"
      option-label-param="name"
      option-name-param="name"
      :label="$t('auth.signup.form.country.label')"
      :placeholder="$t('auth.signup.form.country.placeholder')"
      :disabled="loading"
    />
    <UiInput
      v-model="form.city"
      class="mb-md"
      :label="$t('auth.signup.form.city.label')"
      :placeholder="$t('auth.signup.form.city.placeholder')"
      type="text"
      :error="errors.city"
      autocomplete="off"
    />
    <UiInput
      v-model="form.street"
      class="mb-md"
      :label="$t('auth.signup.form.street.label')"
      :placeholder="$t('auth.signup.form.street.placeholder')"
      type="text"
      :error="errors.street"
      autocomplete="off"
    />
    <UiInput
      v-model="form.phone"
      :is-phone="!!phoneData"
      :phone-data="phoneData"
      :disabled="!countryId"
      class="mb-md"
      :label="$t('auth.signup.form.phone.label')"
      :placeholder="$t('auth.signup.form.phone.placeholder')"
      type="tel"
      :error="errors.phone"
      autocomplete="off"
    />
    <UiSelect
      class="mb-md"
      :value.sync="form.messenger"
      :list="messengers"
      option-value-param="code"
      option-label-param="name"
      option-name-param="name"
      :label="$t('auth.signup.form.messenger.label')"
      :placeholder="$t('auth.signup.form.messenger.placeholder')"
      :disabled="loading"
    />
    <UiInput
      v-model="form.messenger_id"
      class="mb-md"
      :label="$t('auth.signup.form.messenger_id.label')"
      :placeholder="$t('auth.signup.form.messenger_id.placeholder')"
      type="text"
      :error="errors.messenger_id"
      autocomplete="off"
    />
    <UiButton :loading="loading" :disabled="isDisabled" @click="signup">
      {{ $t('buttons.signup') }}
    </UiButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fingerprint from '@/helpers/fingerprintMixin';

export default {
  name: 'Signup',

  mixins: [fingerprint],

  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        country_code: '',
        city: '',
        street: '',
        phone: '',
        messenger: '',
        messenger_id: '',
      },
      loading: false,
      isValid: false,
      errors: {},
    };
  },
  computed: {
    ...mapGetters('misc', ['countries', 'messengers', 'coords']),
    isDisabled() {
      for (let item of Object.values(this.form)) {
        if (item === '') {
          return true;
        }
      }
      return false;
    },
    countryId() {
      const { country_code } = this.form;
      return country_code;
    },
    phoneData() {
      if (!this.countryId) {
        return null;
      }
      const result = this.$_.find(this.countries, { code: this.countryId });
      return result.phone_code ? result : null;
    },
  },
  methods: {
    async signup() {
      this.errors = {};
      this.loading = true;
      try {
        const fingerprint = await this.getFingerprint();
        const recaptchaParams = await this.$recaptchaChallange();
        const coords = {
          geo_lat: this.coords.geo_lat.toString(),
          geo_lon: this.coords.geo_lon.toString(),
        };
        await this.$api.postRegistration({
          ...this.form,
          ...coords,
          ...recaptchaParams,
          fingerprint: fingerprint,
        });
        await this.$router.push('/auth/verify');
      } catch (errors) {
        this.errors = errors?.data?.errors || {};
        if (errors?.data?.errors?.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.captcha_text[0],
          });
        }
        if (errors?.data?.errors?.geo_lat) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.geo_lat[0],
          });
        }
        if (errors?.data?.errors?.geo_lon) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.geo_lon[0],
          });
        }
        throw errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signup {
  &__subtitle {
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
  }
}
</style>
