<template>
  <label
    class="ui-input"
    :class="[
      { 'ui-input_focused': focused },
      { 'ui-input_error': currentError },
      { 'ui-input_search': isSearch },
      { 'ui-input_disabled': disabled },
      { 'ui-input_required': required },
      { 'ui-input_info': !isEditable },
    ]"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <span v-if="label" class="ui-input__label-text">
      {{ label }}
    </span>
    <span
      class="ui-input__wrapper"
      :class="[{ 'ui-input__wrapper_preicon': previousIcon || currency }]"
    >
      <ui-icon
        v-if="previousIcon !== ''"
        :name="previousIcon"
        :size="14"
        :color="$config.cp.text"
        lib="fa"
        substyle="fas"
        class="input-pre-icon"
      />
      <div v-if="currency" class="ui-input__currency">
        {{ currency }}
      </div>
      <div v-if="isPhone" class="ui-input__phone-code">
        +{{ phoneData.phone_code }}
      </div>
      <input
        :placeholder="placeholder"
        :disabled="disabled || !isEditable"
        :tabindex="tabindex"
        :type="currentType"
        :value="currentValue"
        ref="input"
        class="ui-input__element"
        :autocomplete="autocomplete"
        :name="name"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      />
      <span
        v-if="type === 'password'"
        class="ui-input__show-password"
        @click="showPassword"
      >
        <UiIcon
          :size="14"
          :color="$config.cp.placeholder"
          :name="passwordIsShow ? 'eye-slash' : 'eye'"
          lib="fa"
          substyle="fas"
        />
      </span>
      <span
        v-if="isClearable || isSearch"
        :class="{ 'show-clear': showClear }"
        class="ui-input__clear"
        @click="clear"
      >
        <UiIcon
          :size="20"
          :color="$config.cp.placeholder"
          name="times-circle"
          lib="fa"
          substyle="fas"
        />
      </span>
    </span>
    <UiHelp
      v-if="currentError"
      class="mt-2xs"
      :is-error="true"
      :text="currentError"
    />
    <UiHelp v-if="help !== ''" class="mt-2xs" :text="help" />
  </label>
</template>

<script>
export default {
  name: 'UiInput',

  props: {
    value: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    type: {
      type: String,
      default() {
        return 'text';
      },
    },
    isPhone: {
      type: Boolean,
      default() {
        return false;
      },
    },
    currency: {
      type: String,
      default() {
        return '';
      },
    },
    phoneData: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default() {
        return 'Search';
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    help: {
      type: String,
      default() {
        return '';
      },
    },
    isSearch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isClearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    preIcon: {
      type: String,
      default: '',
    },
    postIcon: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default() {
        return 1;
      },
    },
    minSearchLength: {
      type: Number,
      default() {
        return 0;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    scrollTo: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEditable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    name: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
  },

  data() {
    return {
      currentValue: '',
      isValidPhone: false,
      passwordIsShow: false,
      focused: false,
      hovered: false,
      searchTimer: 500,
    };
  },
  computed: {
    currentError() {
      return this.$_.isArray(this.error) ? this.error[0] : this.error;
    },
    currentType() {
      return this.type !== 'password' ||
        (this.type === 'password' && !this.passwordIsShow)
        ? this.type
        : 'text';
    },
    previousIcon() {
      return this.preIcon ? this.preIcon : this.isSearch ? 'search' : '';
    },
    showClear() {
      return (
        this.isSearch &&
        !this.disabled &&
        this.currentValue !== '' &&
        (this.focused || this.hovered)
      );
    },
  },

  watch: {
    currentValue(nv, ov) {
      if (nv !== ov && this.isSearch && nv.length >= this.minSearchLength) {
        this.debouncedSearch();
      }
    },

    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.currentValue =
            newValue === undefined || newValue === null
              ? ''
              : newValue.toString();
        }
      },
      deep: true,
    },
  },

  created() {
    this.currentValue = this.value;
  },

  methods: {
    debouncedSearch() {
      this.debouncedSearch = this.$_.debounce(() => {
        this.$emit('search', this.currentValue);
      }, this.searchTimer);
    },

    showPassword() {
      this.passwordIsShow = !this.passwordIsShow;
    },

    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },

    focus() {
      this.$refs.input.focus();
      if (this.scrollTo) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    },

    handleBlur() {
      this.focused = false;
      this.blur();
    },

    blur() {
      this.$refs.input.blur();
    },

    handleInput(event) {
      const { value } = event.target;
      this.currentValue = value;
      this.$emit('input', value, event);
    },

    handleChange(event) {
      const { value } = event.target;
      this.$emit('change', value);
    },

    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
      this.currentValue = '';
      this.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-input {
  display: flex;
  flex-direction: column;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &__currency {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: var(--color-text);
    padding-right: 12px;
    border-right: 1px solid var(--color-border);
    display: flex;
    align-items: center;
  }
  &__wrapper {
    display: inline-flex;
    border: 1px solid var(--color-border);
    background-color: var(--color-background-fon);
    transition: border-color 0.2s;
    border-radius: 8px;
    overflow: hidden;
    &_preicon {
      padding-left: 12px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px var(--color-background-fon) inset !important;
      color: var(--color-text) !important;
    }
  }
  &__phone-code {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    padding: 0 12px;
    border-right: 1px solid var(--color-border);
    height: 32px;
    display: flex;
    align-items: center;
  }
  &__element {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
    min-width: 10%;
    max-width: 100%;
    flex: 10 1 auto;
    border: none;
    outline: none;
    padding: 0;
    height: 32px;
    background: transparent;
  }
  &__label-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
    line-height: normal;
    margin-bottom: 6px;
  }

  &__show-password {
    cursor: pointer;
    margin-right: 12px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper input {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text);
  }
  &_error & {
    &__wrapper {
      border-color: var(--color-error);
    }
  }
  &_focused & {
    &__wrapper {
      border-color: var(--color-primary);
    }
  }
  &_disabled & {
    &__wrapper {
      opacity: 0.5;
    }
  }
  &_info & {
    &__wrapper {
      background-color: var(--color-background-content);
      border-color: var(--color-background-content);
    }
  }
}
</style>
