<template>
  <div class="layout">
    <router-view />
    <Menu />
  </div>
</template>

<script>
import Menu from '@/components/Menu';

export default {
  name: 'Layout',
  components: {
    Menu,
  },
};
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--color-background-content);
  padding-bottom: 68px;
}
</style>
