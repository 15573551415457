import Connectors from './connectors';
import Interceptors from './interceptors';
import Requests from './requests';

export default {
  install(Vue, config) {
    const { baseApi } = Connectors;

    const baseApiInstance = baseApi(config.baseUrl);

    baseApiInstance.interceptors.request.use(
      Interceptors.requestSuccessInterceptor,
      Interceptors.requestErrorInterceptor
    );
    baseApiInstance.interceptors.response.use(
      Interceptors.responseSuccessInterceptor,
      Interceptors.responseErrorInterceptor
    );

    if (!Vue.prototype.$api) {
      Object.defineProperty(Vue.prototype, '$api', {
        value: new Requests(baseApiInstance),
        writable: false,
      });
    }

    if (!config.store.$api) {
      Object.defineProperty(config.store, '$api', {
        value: Vue.prototype.$api,
        writable: false,
      });
    }
  },
};
