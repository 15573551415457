import _ from 'lodash';
import dayjs from 'dayjs';

export default (App) => {
  if (!App.prototype.$_) {
    Object.defineProperty(App.prototype, '$_', {
      value: _,
      writable: false,
    });
  }

  if (!App.prototype.$dayjs) {
    Object.defineProperty(App.prototype, '$dayjs', {
      value: dayjs,
      writable: false,
    });
  }
};
