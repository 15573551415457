<template>
  <div class="separator"></div>
</template>

<script>
export default {
  name: 'UiSeparator',
};
</script>

<style lang="scss">
.separator {
  width: 100%;
  height: 1px;
  background-color: var(--color-separator);
}
</style>
