<template>
  <i
    class="ui-icon"
    :class="[fontClass, { 'ui-icon_cursor': cursor }]"
    :style="iconStyle"
  />
</template>

<script>
export default {
  name: 'UiIcon',

  props: {
    name: {
      type: String,
      required: true,
    },
    cursor: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: 12,
    },
    color: {
      type: String,
      default: '',
    },
    substyle: {
      type: String,
      default: 'fas',
    },
  },

  computed: {
    fontClass() {
      return `${this.substyle} fa-${this.name}`;
    },

    iconStyle() {
      const size = this.$_.isFinite(this.size)
        ? `${this.size}px`
        : this.$_.toString(this.size);
      return {
        color: this.color,
        fontSize: size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  outline: none;
  line-height: 1;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  user-select: none;
  box-shadow: none;
  box-sizing: border-box;
  &_cursor {
    cursor: pointer;
  }
}
</style>
