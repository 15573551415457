import UiLogo from '@/components/ui/UiLogo.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiInput from '@/components/ui/UiInput.vue';
import UiIcon from '@/components/ui/UiIcon.vue';
import UiTitle from '@/components/ui/UiTitle.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiHelp from '@/components/ui/UiHelp.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTab from '@/components/ui/UiTab.vue';
import UiSeparator from '@/components/ui/UiSeparator.vue';
import UiInfiniteScroll from '@/components/ui/UiInfiniteScroll.vue';
// Not UI
import ItemLogo from '@/components/ItemLogo.vue';
import Status from '@/components/Status.vue';
import Popup from '@/components/layout/Popup';
import Spinner from '@/components/loaders/components/Spinner';

export default (Vue) => {
  Vue.component('UiButton', UiButton);
  Vue.component('UiInput', UiInput);
  Vue.component('UiIcon', UiIcon);
  Vue.component('UiTitle', UiTitle);
  Vue.component('UiCheckbox', UiCheckbox);
  Vue.component('UiHelp', UiHelp);
  Vue.component('UiSelect', UiSelect);
  Vue.component('UiSelect2', UiSelect2);
  Vue.component('UiTab', UiTab);
  Vue.component('UiLogo', UiLogo);
  Vue.component('UiSeparator', UiSeparator);
  Vue.component('UiInfiniteScroll', UiInfiniteScroll);
  // Not UI
  Vue.component('ItemLogo', ItemLogo);
  Vue.component('Status', Status);
  Vue.component('Popup', Popup);
  Vue.component('Spinner', Spinner);
};
