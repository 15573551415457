<template>
  <div
    v-if="user"
    class="status"
    :style="`background-color: ${currentStatus.color}`"
  >
    <UiIcon
      :size="12"
      :name="currentStatus.icon"
      lib="fa"
      substyle="fas"
      class="mr-2xs"
    />
    {{ currentStatus.name }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Status',
  props: {
    status: {
      type: String,
      require: true,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      statusSettings: {
        approved: {
          color: this.$config.cpRaw.approved,
          icon: 'check',
          name: this.$t('statuses.approved'),
        },
        pending: {
          color: this.$config.cpRaw.pending,
          icon: 'spinner',
          name: this.$t('statuses.pending'),
        },
        suspended: {
          color: this.$config.cpRaw.suspended,
          icon: 'pause',
          name: this.$t('statuses.suspended'),
        },
        rejected: {
          color: this.$config.cpRaw.rejected,
          icon: 'times',
          name: this.$t('statuses.rejected'),
        },
        blocked: {
          color: this.$config.cpRaw.blocked,
          icon: 'lock',
          name: this.$t('statuses.blocked'),
        },
        demo: {
          color: this.$config.cpRaw.terms,
          icon: 'check',
          name: this.$t('statuses.demo'),
        },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
    currentStatus() {
      if (this.user.is_demo) {
        return this.statusSettings.demo;
      }
      return this.statusSettings[this.user.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  padding: 0 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.92;
  color: var(--color-text-alter-button);
}
</style>
