import store from '@/store';

export function authGuardCondition(preferIsAuthenticated) {
  return preferIsAuthenticated === store.getters['auth/isAuthenticated'];
}

export default function authGuard(preferIsAuthenticated, route = '/auth') {
  return function (to, from, next) {
    if (!authGuardCondition(preferIsAuthenticated)) {
      next(route);
    } else {
      next();
    }
  };
}
