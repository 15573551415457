<template>
  <div class="ui-select2__input-wrapper">
    <UiIcon
      :size="14"
      color="#7d7d7d"
      name="calendar"
      lib="fa"
      substyle="far"
      class="ui-select2__input-icon"
    />
    <CustomSelect
      v-model="pValue"
      :options="list"
      name="select1"
      class="ui-select2__input"
      @input="$emit('update:value', $event)"
      @change="change"
    ></CustomSelect>
  </div>
</template>

<script>
import CustomSelect from 'v-select2-component';

export default {
  name: 'UiSelect2',
  components: {
    CustomSelect,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      focused: false,
      currentError: null,
      currentValue: '',
    };
  },
  computed: {
    pValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$store.commit('settings/settingsTimezones', newVal);
        localStorage.setItem('select-timezone', newVal);
      },
    },
  },

  watch: {
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
  },

  created() {
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].message
      : this.error;
  },

  methods: {
    focus() {
      this.focused = true;
      this.$emit('focus', event);
    },
    blur() {
      this.focused = false;
    },
    change(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
.ui-select2__input {
  width: 100%;
  &-icon {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 12px;
  }
  &-wrapper {
    position: relative;
  }
}
.select2-container {
  display: block;
  width: 100% !important;
}
.select2-dropdown {
  margin: 0;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgb(0 0 0 / 10%);
  border: 1px solid var(--color-border);
  background-color: var(--color-background-fon);
  min-width: 150px !important;
  &--below {
    margin-top: 12px;
  }
  &--above {
    top: -12px;
  }
}
.select2-results__options {
  padding: 8px;
}
.select2-container--default .select2-search--dropdown {
  padding: 0;
  .select2-search__field {
    border-radius: 8px 8px 0 0;
    border: none;
    border-bottom: 1px solid var(--color-border);
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-text);
    font-family: 'Open Sans', sans-serif;
  }
}
.select2-results__option {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  color: var(--color-text);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #f5f7fa;
  color: var(--color-text);
}
.select2-container--default .select2-selection--single {
  border: 1px solid var(--color-border);
  background-image: linear-gradient(180deg, #fff, #f6f6f6);
  transition: all 0.2s;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: auto;
  .select2-selection__rendered {
    font-size: 14px;
    color: var(--color-text);
    line-height: 32px;
    padding-left: 36px;
    padding-right: 30px;
    height: 32px;
    width: 100%;
    display: block;
  }
  .select2-selection__arrow {
    right: 6px;
    font-weight: 400;
    height: 32px;
    font-family: 'Font Awesome 6 Pro' !important;
    color: #c0c4cc;
    font-size: 14px;
    transition: transform 0.3s;
    cursor: pointer;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotateZ(180deg);
    b {
      display: none;
    }
    &:before {
      content: '\f077';
    }
  }
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  transform: rotateZ(0deg);
}
.select2-container--focus.select2-container--default
  .select2-selection--single {
  border-color: var(--color-primary);
}
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.select2-container--open {
  .select2-dropdown {
    &--below {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-top: 1px solid var(--color-border);
    }
    &--above {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom: 1px solid var(--color-border);
    }
  }
}
</style>
