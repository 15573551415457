<template>
  <div class="request-result">
    <UiIcon
      :size="48"
      :name="icon"
      :color="color"
      lib="fa"
      substyle="fas"
      class="mb-md"
    />
    <div class="request-result__title">
      <slot name="title"></slot>
    </div>
    <div class="request-result__text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RequestResultPopup',
  props: {
    type: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
  },
  computed: {
    icon() {
      return this.type === 'success' ? 'check-circle' : 'times-circle';
    },
    color() {
      return this.type === 'success'
        ? this.$config.cpRaw.success
        : this.$config.cpRaw.error;
    },
  },
};
</script>

<style lang="scss" scoped>
.request-result {
  margin-top: 48px;
  margin-bottom: 48px;
  text-align: center;
  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #303634;
    margin-bottom: 8px;
  }
  &__text {
    font-size: 12px;
    line-height: 1.67;
    color: #2d2d2d;
  }
}
</style>
