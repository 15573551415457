// import { getDictionaries } from '@/api/modules/stubs/getDictionaries.js';

export default {
  getDictionaries() {
    return this.baseApi.get('/tools/dictionaries');
  },
  getSettings() {
    return this.baseApi.get('/tools/settings');
  },
};
