<template>
  <img
    class="logo"
    src="@/assets/images/logo-mobcash.svg"
    alt="logo" />
</template>

<script>
export default {
  name: 'UiLogo',
};
</script>

<style lang="scss">
.logo {
  max-width: 104px;
  margin: 0 auto;
}
</style>
