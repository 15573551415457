<template>
  <transition name="nested" appear>
    <div v-if="isVisible" class="popup">
      <div class="popup__overlay" @click.self="handleClickOutside">
        <div class="popup__window">
          <div class="popup__title">
            <div class="popup__title-wrapper">
              <UiIcon
                class="mr-sm"
                :size="20"
                :name="icon"
                :color="$config.cpRaw.placeholder"
                lib="fa"
                substyle="fas"
              />
              {{ title }}
            </div>
            <div class="popup__title-wrapper">
              <div class="popup__close" @click="close">
                <UiIcon
                  :size="24"
                  name="times"
                  lib="fa"
                  substyle="fas"
                  :color="$config.cpRaw['text-alter']"
                />
              </div>
            </div>
          </div>
          <div class="popup__body">
            <slot name="body"></slot>
          </div>
          <div class="popup__footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Popup',

  props: {
    isVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoadingPopup: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return '';
      },
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    clickOutside: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
  methods: {
    handleClickOutside() {
      if (this.clickOutside) {
        this.close();
      }
    },
    close() {
      this.$emit('update:isVisible', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.nested-enter-active,
.nested-leave-active {
  transition: all 0.2s linear;
}
.nested-enter,
.nested-leave-to {
  opacity: 0;
}
.nested-enter-active .popup__window,
.nested-leave-active .popup__window {
  transition: all 0.2s linear;
}
.nested-enter .popup__window,
.nested-leave-to .popup__window {
  transform: translateY(100%);
}
.popup {
  position: relative;
  z-index: 900;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
  }
  &__window {
    bottom: 0;
    position: absolute;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
    padding: 16px;
    background-color: var(--color-background-fon);
  }
  &__title {
    font-size: 20px;
    line-height: 1.2;
    color: var(--color-text);
    padding-bottom: 16px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__body {
    max-height: calc(var(--vh) * 100px - 140px);
    overflow: auto;
  }
  &__footer {
    display: flex;
    &:empty {
      padding: 0 24px 24px;
    }
  }
}
</style>

<style lang="scss">
.popup {
  p {
    display: block;
    font-size: 14px;
    line-height: 1.71;
    color: #2d2d2d;
    margin-bottom: 16px;
  }
  b {
    font-weight: 700;
  }
}
</style>
