module.exports = {
  getConfig: function () {
    return {
      apiBaseUrl:
        'https://payments-gateway.staging.svc-cloud.com/api-mob-cash/v1',
      variables: {
        primary: '#ffce06',
        alter: '#20815e',
        //fones
        'background-content': '#f7f7f7',
        'background-fon': '#fff',
        //text and icons
        border: '#d3d3d3',
        text: '#303634',
        'text-alter': '#000',
        'text-alter-button': '#fff',
        placeholder: '#7d7d7d',
        separator: '#d8d8d8',
        menu: '#999999',
        //status
        approved: '#20815e',
        pending: '#f37021',
        suspended: '#9a9a9a',
        rejected: '#ff5c70',
        blocked: '#000',
        //states
        error: '#ff5c70',
        success: '#20815e',
        alert: '#f37021',
        block: '#000',
        // terms color
        terms: '#408ce4',
      },
    };
  },
};
