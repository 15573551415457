import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoadingGeo: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated']),
  },

  methods: {
    getLocation() {
      if (
        typeof navigator.geolocation === 'object' &&
        typeof navigator.geolocation.getCurrentPosition === 'function'
      ) {
        navigator.geolocation.getCurrentPosition(
          this.geoDistance,
          this.showError
        );
      } else {
        this.$store.dispatch('misc/setLocationStatus', 0);
      }
    },

    geoDistance(position) {
      this.isLoadingGeo = false;
      const latitudeUser = position.coords.latitude;
      const longitudeUser = position.coords.longitude;

      this.$store.dispatch('misc/setCoords', {
        geo_lat: latitudeUser,
        geo_lon: longitudeUser,
      });
      this.$store.dispatch('misc/setLocationStatus', 1);
      // a = sin²(Δφ/2) + cos(φ1)⋅cos(φ2)⋅sin²(Δλ/2)
      // tanδ = √(a) / √(1−a)
      if (this.isAuthenticated && this.user) {
        const zone = this.user.geo_area; //брать с сервака
        const latitudeAgent = this.user.geo_lat; //брать с сервака
        const longitudeAgent = this.user.geo_lon; //брать с сервака
        const R = 6371e3;
        const { sin, cos, atan2, pow, sqrt } = Math;
        const latitudeAgentRad = (latitudeAgent * Math.PI) / 180,
          longitudeAgentRad = (longitudeAgent * Math.PI) / 180;
        const latitudeUserRad = (latitudeUser * Math.PI) / 180,
          longitudeUserRad = (longitudeUser * Math.PI) / 180;
        const deltaLatitude = latitudeUserRad - latitudeAgentRad,
          deltaLongitude = longitudeUserRad - longitudeAgentRad;
        const a =
          pow(sin(deltaLatitude / 2), 2) +
          cos(latitudeAgentRad) *
            cos(latitudeUserRad) *
            pow(sin(deltaLongitude / 2), 2);
        const tang = 2 * atan2(sqrt(a), sqrt(1 - a));
        const distance = R * tang;
        this.$store.dispatch('misc/setDistance', distance);
        if (this.user && this.user?.is_demo) {
          this.$store.dispatch('misc/setLocationStatus', 1);
        } else {
          if (distance <= zone) {
            this.$store.dispatch('misc/setLocationStatus', 1);
          } else {
            this.$store.dispatch('misc/setLocationStatus', 2);
          }
        }
      }
    },

    showError(error) {
      this.isLoadingGeo = false;
      switch (error.code) {
        case error.PERMISSION_DENIED:
          this.$store.dispatch('misc/setLocationStatus', 0);
          break;
        case error.POSITION_UNAVAILABLE:
          this.$store.dispatch('misc/setLocationStatus', 0);
          break;
      }
    },
  },
};
