<template>
  <div class="demo">
    <template v-if="!isSended">
      <UiInput
        v-model="form.email"
        class="mb-md"
        :label="$t('auth.signup.form.email.label')"
        :placeholder="$t('auth.signup.form.email.placeholder')"
        type="text"
        :error="errors.email"
        autocomplete="off"
      />
      <UiSelect
        class="mb-md"
        :value.sync="form.messenger"
        :list="messengers"
        option-value-param="code"
        option-label-param="name"
        option-name-param="name"
        :label="$t('auth.signup.form.messenger.label')"
        :placeholder="$t('auth.signup.form.messenger.placeholder')"
        :disabled="loading"
      />
      <UiInput
        v-model="form.messenger_id"
        class="mb-md"
        :label="$t('auth.signup.form.messenger_id.label')"
        :placeholder="$t('auth.signup.form.messenger_id.placeholder')"
        type="text"
        :error="errors.messenger_id"
        autocomplete="off"
      />
      <UiSelect
        class="mb-md"
        :value.sync="form.country_code"
        :list="countries"
        option-value-param="code"
        option-label-param="name"
        option-name-param="name"
        :label="$t('auth.signup.form.country.label')"
        :placeholder="$t('auth.signup.form.country.placeholder')"
        :disabled="loading"
      />
      <UiButton :loading="loading" :disabled="isDisabled" @click="requestDemo">
        {{ $t('buttons.demo') }}
      </UiButton>
    </template>
    <template v-else>
      <div class="demo__complete-request">
        <UiIcon
          class="mb-lg"
          :size="64"
          :color="$config.cpRaw.success"
          name="check-circle"
          lib="fa"
          substyle="fas"
        />
        {{ $t('auth.demo.text') }}
      </div>
    </template>
  </div>
</template>

<script>
import fingerprint from '@/helpers/fingerprintMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'Demo',

  mixins: [fingerprint],

  data() {
    return {
      form: {
        email: '',
        country_code: '',
        messenger: '',
        messenger_id: '',
      },
      isSended: false,
      loading: false,
      isValid: false,
      errors: {},
    };
  },

  computed: {
    ...mapGetters('misc', ['countries', 'messengers']),

    isDisabled() {
      for (let item of Object.values(this.form)) {
        if (item === '') {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    async requestDemo() {
      this.errors = {};
      this.loading = true;
      try {
        const fingerprint = await this.getFingerprint();
        const recaptchaParams = await this.$recaptchaChallange();

        await this.$api.postDemoRegistration({
          ...this.form,
          ...recaptchaParams,
          fingerprint: fingerprint,
        });
        this.isSended = true;
      } catch (errors) {
        this.errors = errors?.data?.errors || {};
        if (errors?.data?.errors?.captcha_text) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.captcha_text[0],
          });
        }
        throw errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.demo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__text {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
  }
  &__complete-request {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75;

    min-height: 220px;
  }
}
</style>
