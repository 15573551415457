import auth from './modules/auth';
import i18n from './modules/i18n';
import misc from './modules/misc';
import user from './modules/user';
import payments from './modules/payments';

export default class Requests {
  constructor(baseApi) {
    Object.assign(this, { baseApi });

    const requests = {
      ...auth,
      ...i18n,
      ...misc,
      ...user,
      ...payments,
    };

    Object.assign(this, requests);
  }
}
