<template>
  <div>
    <div v-if="!isDemo">
      <div class="account-deposit__panel account-deposit__panel_no-icon">
        <div class="account-deposit__panel-info">
          <div class="account-deposit__panel-title">
            {{ $t('accountDeposit.info.deposit') }}
          </div>
          <div class="account-deposit__panel-value">
            {{ info.min_deposit }} {{ info.coin }}
          </div>
        </div>
      </div>
      <div class="account-deposit__panel account-deposit__panel_no-icon">
        <div class="account-deposit__panel-info">
          <div class="account-deposit__panel-title">
            {{ $t('accountDeposit.info.offset') }}
          </div>
          <div class="account-deposit__panel-value">
            {{ info.offset_percent | formatPercent }}
          </div>
        </div>
      </div>
      <div class="account-deposit__panel account-deposit__panel_no-icon">
        <div class="account-deposit__panel-info">
          <div class="account-deposit__panel-title">
            {{
              $t('accountDeposit.info.rate', {
                currency: channel.currency_code,
              })
            }}
          </div>
          <div class="account-deposit__panel-value">
            {{ info.rate | formatMoney }}
          </div>
        </div>
      </div>
      <p class="mb-lg" v-html="$t('accountDeposit.perfectMoney.text1')" />
      <UiInput
        v-model="amount"
        :currency="info.coin"
        :label="$t('accountDeposit.perfectMoney.amount')"
        placeholder=""
        class="mb-sm"
        type="text"
        autocomplete="off"
        :error="errors.amount"
        @input="changeSum"
      />
      <div class="account-deposit__recieve">
        {{ $t('accountDeposit.perfectMoney.receive') }}:
        <span> {{ pReceive }} {{ channel.currency_code }}</span>
      </div>
      <div
        class="mb-lg account-deposit__text"
        v-html="$t('accountDeposit.perfectMoney.text2')"
      />
      <UiButton class="w-full" :loading="loading" @click="depositPerfectMoney">
        {{ $t('buttons.proceed') }}
      </UiButton>
      <form ref="form" method="post" :action="action">
        <input type="hidden" name="PAYEE_ACCOUNT" :value="form.account" />
        <input type="hidden" name="PAYEE_NAME" :value="form.name" />
        <input type="hidden" name="PAYMENT_UNITS" :value="form.payment_units" />
        <input type="hidden" name="PAYMENT_ID" :value="form.payment_id" />
        <input type="hidden" name="STATUS_URL" :value="form.status_url" />
        <input type="hidden" name="PAYMENT_URL" :value="form.returnUrl" />
        <input type="hidden" name="PAYMENT_URL_METHOD" value="GET" />
        <input type="hidden" name="NOPAYMENT_URL" :value="form.returnUrl" />
        <input type="hidden" name="NOPAYMENT_URL_METHOD" value="GET" />
        <input type="hidden" name="PAYMENT_AMOUNT" :value="form.amount" />
      </form>
    </div>
    <p v-else class="mb-lg" v-html="$t('accountDeposit.perfectMoney.demo')" />
  </div>
</template>

<script>
import helper from './helper';

export default {
  name: 'PerfectMoney',

  mixins: [helper],

  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    isDemo: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      errors: {},
      loading: false,
      action: '',
      form: {
        account: '',
        name: '',
        payment_id: '',
        status_url: '',
        payment_units: '',
        amount: '',
        returnUrl: '',
      },
    };
  },

  methods: {
    async depositPerfectMoney() {
      this.errors = {};
      this.loading = true;
      try {
        const response = await this.$api.postUserDepositPerfectMoney({
          amount: this.amount,
        });
        const data = response.data.payload[0];
        this.form = {
          account: data.account,
          name: data.name,
          payment_id: data.payment_id,
          status_url: data.status_url,
          payment_units: data.payment_units,
          amount: this.amount,
          returnUrl: `${location.origin}${location.pathname}?complete=fail`,
        };
        this.action = data.action_url;
        console.log(this.$refs.form);
        this.$nextTick(() => {
          this.$refs.form.submit();
        });
      } catch (error) {
        this.errors = error.data.errors;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.account-deposit {
  &__text {
    font-size: 12px;
    line-height: 1.67;
    margin-bottom: 16px;
    color: #2d2d2d;
  }
  &__recieve {
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    color: var(--color-terms);
    margin-bottom: 16px;
    span {
      color: var(--color-text);
    }
  }
}
</style>
