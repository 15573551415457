export default {
  getUserProfile() {
    return this.baseApi.get('/user/profile');
  },
  getUserWallet() {
    return this.baseApi.get('/user/wallet');
  },
  getUserWalletCheck() {
    return this.baseApi.get('user/wallet/check');
  },
  postUserDepositPerfectMoney(payload) {
    return this.baseApi.post('user/deposit/perfect-money', payload);
  },
};
