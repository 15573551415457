<template>
  <div
    class="ui-button"
    :class="[
      `ui-button_type-${type}`,
      {
        'ui-button_disabled': isBtnDisabled,
        'ui-button_loading': loading,
      },
    ]"
    @click="onButtonClick"
  >
    <UiIcon
      v-if="icon"
      :name="icon"
      :substyle="substyle"
      :color="getIconColor"
      :size="16"
      class="ui-button__icon"
    />
    <span class="ui-button__text">
      <slot />
    </span>
    <div v-if="loading" class="ui-button__loading-cover">
      <UiIcon :color="getIconColor" :size="16" name="spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isActive: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    type: {
      type: String,
      default() {
        return 'default';
      },
    },
    icon: {
      type: String,
      default() {
        return '';
      },
    },
    substyle: {
      type: String,
      default() {
        return 'fas';
      },
    },
    iconSize: {
      type: [String, Number],
      default: '',
    },
    iconColor: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedIconColor() {
      return this.iconColor === null ? this.$config.cp.text : this.iconColor;
    },
    isBtnDisabled() {
      return this.disabled || this.loading;
    },
    getIconColor() {
      if (this.type === 'default') {
        return this.$config.cp['on-primary'];
      }
      return this.computedIconColor;
    },
  },
  methods: {
    onButtonClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  padding: 12px;
  text-transform: uppercase;
  color: var(--color-text-alter);
  &__icon {
    margin-right: 4px;
  }
  &_type {
    &-default {
      background-color: var(--color-primary);
    }
    &-secondary {
      border: 1px solid var(--color-primary);
    }
    &-alter {
      background-color: var(--color-alter);
      color: var(--color-text-alter-button);
    }
  }
  &_disabled {
    cursor: not-allowed;
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  }
  &_loading & {
    &__icon,
    &__text {
      opacity: 0;
    }
  }
  &__loading-cover {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
