<template>
  <div class="history-item">
    <div class="flex justify-between items-center mb-sm">
      <div class="history-item__date">
        <UiIcon
          :size="12"
          name="calendar"
          :color="$config.cpRaw.placeholder"
          lib="fa"
          substyle="far"
          class="mr-2xs"
        />
        {{ (item.finished_at * 1000) | formatDateTime }}
      </div>
      <div class="history-item__id">
        {{ $t('history.item.id', { id: item.transaction_id }) }}
      </div>
    </div>
    <UiSeparator class="mb-md" />
    <div class="flex justify-between items-center mb-sm">
      <div class="history-item__type">
        <UiIcon
          :size="14"
          :name="icon"
          :color="color"
          lib="fa"
          substyle="far"
          class="mr-2xs"
        />
        {{ historyItemName }}
      </div>
      <div class="history-item__amount">
        {{ item.amount | formatMoney }} {{ currencyCode }}
      </div>
    </div>
    <div class="flex justify-between items-center mb-sm">
      <div class="history-item__fee">
        <UiIcon
          :size="12"
          name="percent"
          :color="$config.cpRaw.placeholder"
          lib="fa"
          substyle="far"
          class="mr-2xs"
        />
        {{ $t('history.item.fee') }}
      </div>
      <div class="history-item__fee-id">
        {{ item.fee | formatMoney }} {{ currencyCode }}
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="history-item__player">
        <UiIcon
          :size="12"
          name="user"
          :color="$config.cpRaw.placeholder"
          lib="fa"
          substyle="far"
          class="mr-2xs"
        />
        {{ $t('history.item.playerId') }}
      </div>
      <div class="history-item__player-id">{{ item.customer_id }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HistoryItem',
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['currencyCode']),
    historyItemName() {
      return this.$t(`history.item.type.${this.item.type}`);
    },
    icon() {
      return this.item.type === 'deposit' ? 'plus-circle' : 'down';
    },
    color() {
      return this.item.type === 'deposit'
        ? this.$config.cpRaw.alter
        : this.$config.cpRaw.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-item {
  padding: 12px 18px;
  background-color: var(--color-background-fon);
  margin-bottom: 8px;
  border-radius: 8px;
  color: var(--color-text);
  &__date {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }
  &__id {
    font-size: 11px;
    line-height: 1.45;
    color: var(--color-placeholder);
  }
  &__type,
  &__amount {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.14;
  }
  &__fee,
  &__player {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    &-id {
      font-size: 12px;
    }
  }
}
</style>
