import Vue from 'vue';
import VueI18n from 'vue-i18n';
import baseTranslations from '@/i18n/base-translations';
import _ from 'lodash';

Vue.use(VueI18n);

export const defaultLanguage = localStorage.getItem('language') || 'en';

Vue.use(VueI18n);

class VueI18nExtend extends VueI18n {
  getChoiceIndex(choice, choicesLength) {
    // eslint-disable-next-line no-empty
    if (this.locale !== 'ru') {
    }
    if (choice === 0) {
      return 0;
    }
    const teen = choice > 10 && choice < 20;
    const endsWithOne = choice % 10 === 1;
    if (!teen && endsWithOne) {
      return 1;
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2;
    }
    return choicesLength < 4 ? 2 : 3;
  }
}

const loadedLanguages = [];

const i18n = new VueI18nExtend({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  if (Vue.prototype.$api) {
    const { $api } = Vue.prototype;
    $api.baseApi.defaults.headers.common['x-language'] = lang;
  }
  return lang;
}

export function loadLanguageAsync(lang, force = false) {
  setI18nLanguage(lang);

  if (!force) {
    if (i18n.locale === lang || loadedLanguages.includes(lang)) {
      return Promise.resolve(lang);
    }
  }
  if (Vue.prototype.$api) {
    const { $api } = Vue.prototype;
    return $api
      .getTranslation(lang)
      .then((response) => {
        const { translations } = response.data.payload;
        let result = {};
        _.merge(result, baseTranslations, translations);

        loadedLanguages.push(lang);
        i18n.setLocaleMessage(lang, result);
      })
      .catch(() => {
        console.log('i18n-debug: setLocaleMessage fallback', {
          i18n: baseTranslations,
          lang,
        });
      });
  }
}

export default i18n;
