<template>
  <div>
    <div class="account-deposit__panel">
      <div class="account-deposit__panel-info">
        <div class="account-deposit__panel-title">
          {{ $t('accountDeposit.info.network') }}
        </div>
        <div class="account-deposit__panel-value">
          {{ info.network }}
        </div>
      </div>
      <img src="@/assets/images/network.svg" alt="logo" />
    </div>
    <div class="account-deposit__panel" @click="copy">
      <div class="account-deposit__panel-info">
        <div class="account-deposit__panel-title">
          {{ $t('accountDeposit.info.address') }}
        </div>
        <div
          :class="[
            'account-deposit__panel-value',
            'copy',
            { 'account-deposit__panel-value_ellipsis': !isDemo },
          ]"
        >
          {{ info.wallet }}
        </div>
      </div>
      <UiIcon
        v-if="!isDemo"
        :size="20"
        name="copy"
        :color="$config.cpRaw.alter"
        lib="fa"
        substyle="far"
      />
    </div>
    <div class="account-deposit__panel account-deposit__panel_no-icon">
      <div class="account-deposit__panel-info">
        <div class="account-deposit__panel-title">
          {{ $t('accountDeposit.info.deposit') }}
        </div>
        <div class="account-deposit__panel-value">
          {{ info.min_deposit }} {{ info.coin }}
        </div>
      </div>
    </div>
    <div class="account-deposit__panel account-deposit__panel_no-icon">
      <div class="account-deposit__panel-info">
        <div class="account-deposit__panel-title">
          {{ $t('accountDeposit.info.offset') }}
        </div>
        <div class="account-deposit__panel-value">{{ info.offset_percent | formatPercent }}</div>
      </div>
    </div>
    <div class="account-deposit__panel account-deposit__panel_no-icon">
      <div class="w-full">
        <div class="account-deposit__panel-info">
          <div class="account-deposit__panel-title">
            {{
              $t('accountDeposit.info.rate', {
                currency: channel.currency_code,
              })
            }}
          </div>
          <div class="account-deposit__panel-value">
            {{ info.rate | formatMoney }}
          </div>
        </div>
        <div v-if="!isDemo" class="account-deposit__calc">
          <UiSeparator class="my-md" />
          <div class="account-deposit__panel-info mb-md">
            <div class="account-deposit__calc-title">
              {{ $t('accountDeposit.info.send') }}
            </div>
            <div class="account-deposit__calc-value">
              <UiInput
                v-model="amount"
                :currency="info.coin"
                placeholder=""
                type="text"
                autocomplete="off"
                @input="changeSum"
              />
            </div>
          </div>
          <div class="account-deposit__panel-info">
            <div class="account-deposit__calc-title">
              {{ $t('accountDeposit.tron.receive') }}
            </div>
            <div class="account-deposit__panel-value">
              {{ pReceive }} {{ channel.currency_code }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      class="mb-lg"
      v-html="$t('accountDeposit.tron.text1', { network: info.network })"
    />
    <p v-html="$t('accountDeposit.tron.text2', { network: info.network })" />
  </div>
</template>

<script>
import helper from './helper';

export default {
  name: 'Tron',

  mixins: [helper],

  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    isDemo: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
