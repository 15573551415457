const baseConfigModule = require('../default');

module.exports = {
  getConfig: function () {
    const result = baseConfigModule.getConfig();
    result.apiBaseUrl = 'https://bw-pay-gw.com/api-mob-cash/v1';

    return result;
  },
};
