<template>
  <div class="verify">
    <UiIcon
      class="mb-lg"
      :size="64"
      :color="$config.cpRaw.success"
      name="envelope"
      lib="fa"
      substyle="fas"
    />
    {{ $t('auth.verify.text') }}
  </div>
</template>

<script>
export default {
  name: 'Verify',
};
</script>

<style lang="scss">
.verify {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  text-align: center;

  font-size: 16px;
  font-weight: 600;
  line-height: 1.75;
}
</style>
