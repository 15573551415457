import Vue from 'vue';
import App from './App.vue';
import ConfigService from '@/config';
import store from '@/store';
import router from '@/router';

import api from '@/api';
import i18n from '@/i18n';

import PluginsSetup from '@/setup/plugins';
import ComponentsSetup from '@/setup/components';
import LibsSetup from '@/setup/libs';
import NavigationGuardsSetup from '@/setup/navigationGuards';
import eventBus from '@/setup/eventBus';
import FiltersSetup from '@/setup/filters';

import '@/styles';

PluginsSetup(Vue);
ComponentsSetup(Vue);
LibsSetup(Vue);

NavigationGuardsSetup(Vue);
eventBus(Vue);
FiltersSetup(Vue);

const config = ConfigService.params;
const apiConfig = {
  store,
  router,
  baseUrl: config.apiBaseUrl,
};

Vue.use(api, apiConfig);
Vue.use(ConfigService);

// router here because of style ordering
new Vue({
  store,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
