import Vue from 'vue';
const configModule = require('./config');
const config = configModule.getConfig(true);

export class ConfigService {
  params = config;

  detectSize() {
    const height = window.innerHeight / 100;

    document.documentElement.style.setProperty('--vh', height.toString());
  }

  initVariables() {
    const cp = {};
    const cpRaw = {};

    Object.keys(this.params.variables).forEach((color) => {
      const name = `--color-${color}`;
      cp[color] = `var(${name})`;
      cpRaw[color] = this.params.variables[color];
      document.documentElement.style.setProperty(
        name,
        this.params.variables[color]
      );
    });

    Vue.set(this, 'cp', cp);
    Vue.set(this, 'cpRaw', cpRaw);
  }

  install(Vue) {
    Vue.util.defineReactive(this, 'height', '');
    Vue.util.defineReactive(this, 'cp', {});
    Vue.util.defineReactive(this, 'cpRaw', {});

    this.detectSize();
    this.initVariables();

    Vue.prototype.$config = this;
  }
}

export default new ConfigService();
