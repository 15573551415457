<template>
  <div class="menu">
    <router-link
      v-for="(item, id) in mainMenu"
      :title="item.name"
      :to="item.link"
      :key="id"
      :class="[{ menu__item_active: isLinkActive(item.key) }]"
      :disabled="item.disabled"
      class="menu__item"
    >
      <UiIcon
        class="mb-sm"
        :size="18"
        :name="item.icon"
        lib="fa"
        substyle="fas"
      />
      <div class="menu__item-text">
        {{ item.name }}
      </div>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import store from '@/store';

export default {
  name: 'Menu',

  computed: {
    ...mapGetters('auth', ['user']),
    ...mapGetters('misc', ['stopStatusList']),
    mainMenu() {
      return [
        {
          name: this.$t('menu.dashboard'),
          icon: 'home',
          link: '/dashboard',
          key: ['dashboard', 'account-deposit'],
        },
        {
          name: this.$t('menu.history'),
          icon: 'list',
          link: '/history',
          disabled: this.isDisabled,
          key: ['history'],
        },
        {
          name: this.$t('menu.statistics'),
          icon: 'chart-bar',
          link: '/statistics',
          disabled: this.isDisabled,
          key: ['statistics'],
        },
        {
          name: this.$t('menu.profile'),
          icon: 'user',
          link: '/profile',
          key: ['profile'],
        },
      ];
    },

    isDisabled() {
      const userStatus = store.state?.auth?.user?.status || '';
      return this.stopStatusList.includes(userStatus);
    },

    currentRoute() {
      return this.$route.meta.source;
    },
  },

  methods: {
    isLinkActive(name) {
      return name.includes(this.currentRoute);
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
  display: flex;
  padding: 12px 0;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--color-background-fon);
  &__item {
    color: var(--color-menu);
    text-decoration: none;
    text-align: center;
    flex: 1 0 auto;
    position: relative;
    width: 25%;
    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
    &-text {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
    }
    &_active {
      color: var(--color-alter);
    }
  }
}
</style>
