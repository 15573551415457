export default {
  months: {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
  chart: {
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
  },
  popups: {
    withdraw: {
      title: 'Withdraw from Account',
      text: 'Enter the ID of the transaction for <b>withdraw</b>',
      form: {
        id: {
          label: 'Transaction ID',
          placeholder: 'Enter Transaction ID',
        },
        code: {
          label: 'Security Code',
          placeholder: 'Your Security Code',
        },
        amount: {
          label: 'Amount',
        },
      },
      success: {
        title: 'Payment was successful',
        text: "Player's balance will be changed shortly",
      },
      error: {
        title: 'Payment was failed',
        text: 'Try again or contact support service',
      },
    },
    topUp: {
      title: 'Top-Up Account',
      text: 'Enter the ID of the transaction for <b>deposit</b>',
      form: {
        id: {
          label: 'Transaction ID',
          placeholder: 'Enter Transaction ID',
        },
        amount: {
          label: 'Amount',
        },
      },
      success: {
        title: 'Payment was successful',
        text: "Player's balance will be changed shortly",
      },
      error: {
        title: 'Payment was failed',
        text: 'Try again or contact support service',
      },
    },
  },
  menu: {
    dashboard: 'Dashboard',
    history: 'History',
    statistics: 'Statistics',
    profile: 'Profile',
  },
  statuses: {
    approved: 'Approved',
    pending: 'Pending',
    suspended: 'Suspended',
    rejected: 'Rejected',
    blocked: 'Blocked',
    demo: 'Demo',
  },
  accountDeposit: {
    title: 'Agent Account Deposit',
    chooseWay: 'Choose Preferred Way',
    response: {
      success: 'Deposit Success',
      decline: 'Deposit Failed',
    },
    info: {
      network: 'Network',
      address: 'Address',
      deposit: 'Min. deposit',
      offset: 'Offset',
      rate: 'Current Rate USDT/{currency}',
      send: 'I Send',
    },
    tron: {
      receive: 'I Receive',
      text1:
        'Send only <strong>{network}</strong> to provided deposit address.',
      text2:
        'If you send any other crypto except {network} to this address, you’ll lose your assets.',
    },
    perfectMoney: {
      demo: 'We will provide the instruction in Live mode.',
      amount: 'Amount',
      receive: 'You will recieve',
      text1:
        '<strong>Specify the required amount in USD</strong> which will be credited to your agent account. The total amount in local currency with included compensation will be displayed under the field.',
      text2:
        'The calculation is approximate, as the exchange rate may change during the time the payment is being processed.',
    },
    copied: 'Copied',
  },
  dashboard: {
    title: 'Dashboard',
    warning: 'Kindly provide your ID to our manager in Whatsapp',
    balance: {
      title: 'Available Balance',
      help: "<p>You can make transactions with player's accounts within this amount.</p><p>Each accepted deposit reduces the balance, and withdrawals increase the balance.</p><p>To add funds to your balance — <span>press «+» button</span> and follow the instructions.</p>",
    },
    profit: {
      total: 'Total Profit',
      deposit: 'Profit from Deposits ({percent})',
      payout: 'Profit from Payouts ({percent})',
    },
  },
  history: {
    title: 'History',
    empty: 'No Available Transactions',
    tabs: {
      deposit: 'Deposits',
      withdraw: 'Withdrawals',
      all: 'All',
    },
    item: {
      type: {
        deposit: 'Deposit',
        withdraw: 'Withdrawal',
      },
      id: 'ID: {id}',
      playerId: 'Player ID',
      fee: 'Fee',
    },
  },
  statistics: {
    title: 'Statistics',
  },
  profile: {
    title: 'Profile',
    id: 'Cashbox ID: {id}',
    country: 'Country',
    city: 'City',
    street: 'Street',
    copied: 'Copied',
    updated: 'Updated',
  },
  buttons: {
    login: 'Log in',
    signup: 'SIGN UP',
    topup: 'Top Up',
    withdraw: 'Withdraw',
    gotit: 'Got It',
    cancel: 'Cancel',
    logout: 'Logout',
    changePassword: 'Change Password',
    supportService: 'Support Service',
    copy: 'Copy',
    refresh: 'Refresh',
    demo: 'REQUEST',
    proceed: 'Proceed Payment',
  },
  location: {
    blockedText: 'Allow location access on this web-site to continue',
    outText:
      "You're out of the allowed area. Kindly return to it as soon as possible.",
    copyLocation:
      'Current Location: {userLat} / {userLong}\nInitial Location: {pointLat} / {pointLong}\nAllowed Area: {area} meters\nPoint range is {distance} meters',
    info: {
      currentPosition:
        '<strong>Current Location:</strong> {userLat} / {userLong}',
      initialPosition:
        '<strong>Initial Location:</strong> {pointLat} / {pointLong}',
      area: '<strong>Allowed Area:</strong> {area} meters',
      range: '<strong>Point range is</strong> {distance} meters',
    },
  },
  auth: {
    login: {
      title: 'Log In',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Your Email',
        },
        password: {
          label: 'Password',
          help: 'Password must contain at least 6 characters and three character types: uppercase letters, lowercase letters, numbers and symbols.',
        },
        passwordRepeat: {
          label: 'Repeat Password',
        },
        rememberMe: 'Remember Me',
      },
      account: {
        text: "Don't have an account?",
        button: 'Sign Up',
      },
      demo: {
        text: 'Want to check how it works?',
        button: 'Request Demo',
      },
    },
    demo: {
      title: 'Request Demo',
      text: 'Demo account credentials were sent to your email',
    },
    signup: {
      title: 'Sign Up',
      subtitle: 'All fields are required',
      form: {
        agree: 'I agree to the <a href="#">Terms of Conditions</a>',
        firstName: {
          label: 'First Name',
          placeholder: 'Your First Name',
        },
        lastName: {
          label: 'Last Name',
          placeholder: 'Your Last Name',
        },
        email: {
          label: 'Email',
          placeholder: 'Your Email',
        },
        country: {
          label: 'Country',
          placeholder: 'Select Country',
        },
        city: {
          label: 'City',
          placeholder: 'Your City',
        },
        street: {
          label: 'Street',
          placeholder: 'Your Street',
        },
        phone: {
          label: 'Phone Number',
          placeholder: 'Without Country Code',
        },
        messenger: {
          label: 'Messenger',
          placeholder: 'Select Messenger',
        },
        messenger_id: {
          label: 'Messenger ID',
          placeholder: 'Contact Number or Username',
        },
      },
    },
    verify: {
      title: 'Verify Your E-mail',
      text: 'You’ll need to confirm your email address to continue.',
    },
  },
};
