<template>
  <label class="ui-checkbox" :class="{ 'ui-checkbox_disabled': disabled }">
    <input
      class="ui-checkbox__item"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <span class="ui-checkbox__checkmark">
      <UiIcon :size="12" name="check" lib="fa" substyle="fas" />
    </span>
    <slot>
      {{ label }}
    </slot>
  </label>
</template>

<script>
export default {
  name: 'UiCheckbox',

  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default() {
        return '';
      },
    },
    modelValue: {
      type: [String, Boolean],
      default() {
        return '';
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    trueValue: {
      type: Boolean,
      default() {
        return true;
      },
    },
    falseValue: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: var(--color-text);
  user-select: none;
  &__checkmark {
    height: 24px;
    width: 24px;
    min-width: 24px;
    border-radius: 8px;
    background-color: var(--color-background-fon);
    border: 1px solid var(--color-border);
    margin-right: 8px;
    transition: 0.2s ease-out;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      opacity: 0;
    }
  }
  &__item {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .ui-checkbox__checkmark {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      i {
        opacity: 1;
      }
    }
  }
  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
