<template>
  <div v-if="popups.length" class="popup-manager">
    <component
      v-for="(popup, index) in popups"
      :key="popup.id"
      ref="popup"
      :is="popup.component"
      v-bind="popup.data"
      v-on="popup.listeners"
      @close="handleClose(index)"
    />
  </div>
</template>

<script>
import TopUp from '@/components/popups/TopUp.vue';
import Withdraw from '@/components/popups/Withdraw.vue';

export default {
  name: 'PopupManager',
  components: {
    TopUp,
    Withdraw,
  },
  data() {
    return {
      nextId: 0,
      popups: [],
    };
  },
  mounted() {
    this.$eventBus.$on('open-popup', this.handleOpenConfirm);
  },

  beforeDestroy() {
    this.$eventBus.$off('open-popup', this.handleOpenConfirm);
  },

  methods: {
    handleOpenConfirm(component, payload = {}, existBehaviour = {}) {
      const { key, onExist } = existBehaviour;
      if (key) {
        const isExist = this.popups.some((item) => item.key === key);
        if (isExist) {
          if (onExist && typeof onExist === 'function') {
            onExist();
          }
          return;
        }
      }

      const id = this.nextId++;
      const { data, listeners } = payload;
      const confirmData = {
        id,
        component,
        data: data,
        listeners: listeners,
        key: key,
      };

      this.popups.push(confirmData);

      this.$nextTick(() => {
        const popup = this.$refs.popup.find((ref) => ref.$vnode.key === id);
        popup.open();
      });
    },
    handleClose(index) {
      if (this.popups[index].component === this.popupQueryParam) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });

        localStorage.removeItem('popupManagerData');
      }

      this.popups.splice(index, 1);
    },
  },
};
</script>
