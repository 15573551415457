<template>
  <div
    class="ui-select"
    :class="[
      { 'ui-select_focused': focused },
      { 'ui-select_error': currentError },
      { 'ui-select_disabled': disabled },
      { 'ui-select_no-border': !border },
    ]"
  >
    <span v-if="label" class="ui-select__label-text">
      {{ label }}
    </span>
    <span class="ui-select__wrapper">
      <span v-if="isDynamic" ref="shadowSpan" class="resizeable-shadow"></span>
      <el-select
        ref="elSelect"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :multiple="multiple"
        :filterable="filterable"
        :filter-method="filterMethod"
        class="ui-select__element"
        :popper-class="`ui-select__dropdown ${popperClass}`"
        @change="change"
        @input="$emit('update:value', $event)"
        @focus="focus"
        @blur="blur"
        @visible-change="handleVisibleChange"
      >
        <template
          v-if="isItemIco && value !== '' && isIconsDictionary !== ''"
          slot="prefix"
        >
          <ItemLogo :link="itemLogo" />
        </template>
        <template v-if="prependIcon !== ''" slot="prefix">
          <UiIcon
            :size="14"
            :color="$config.cp.placeholder"
            substyle="far"
            :name="prependIcon"
          />
        </template>
        <template slot="empty">
          <div class="ui-select__no-data">No matching data</div>
        </template>
        <el-option
          v-for="item in list"
          :key="item[optionValueParam]"
          :label="item[optionLabelParam]"
          :value="item[optionValueParam]"
        >
          <template v-if="isListItemIco">
            <ItemLogo :link="item.image" />
          </template>
          <span>{{ item[optionNameParam] }}</span>
        </el-option>
      </el-select>
    </span>
    <UiHelp
      v-if="currentError"
      class="mt-2xs"
      :is-error="true"
      :text="currentError"
    />
  </div>
</template>

<script>
export default {
  name: 'UiSelect',
  props: {
    value: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    label: {
      type: String,
      default() {
        return '';
      },
    },
    placeholder: {
      type: [String, Number],
      default() {
        return 'Search';
      },
    },
    filterable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    error: {
      type: [String, Number, Array],
      default() {
        return '';
      },
    },
    errorTooltip: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    border: {
      type: Boolean,
      default() {
        return true;
      },
    },
    optionNameParam: {
      type: String,
      default() {
        return 'name';
      },
    },
    optionLabelParam: {
      type: String,
      default() {
        return 'name';
      },
    },
    optionValueParam: {
      type: String,
      default() {
        return 'id';
      },
    },
    prependIcon: {
      type: String,
      default() {
        return '';
      },
    },
    isItemIco: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isListItemIco: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isIconsDictionary: {
      type: String,
      default() {
        return '';
      },
    },
    popperClass: {
      type: String,
      default() {
        return '';
      },
    },
    filterMethod: {
      type: Function,
    },
    isDynamic: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      focused: false,
      currentError: null,
      currentValue: '',
    };
  },
  computed: {
    itemLogo() {
      const dict = this.$store?.state?.misc[this.isIconsDictionary];
      return this.value !== '' && dict ? dict[this.value]?.image : '';
    },
    dynamicParams() {
      return {
        value: this.value,
        list: this.list,
      };
    },
  },

  watch: {
    error(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.currentError = this.$_.isArray(newValue) ? newValue[0] : newValue;
      }
    },
    dynamicParams() {
      if (this.isDynamic) {
        this.adjustSize();
      }
    },
  },

  created() {
    this.currentValue = this.value;
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].message
      : this.error;

    this.$eventBus.$on('filterable-select-fix', this.onFilterableSelectFix);
  },
  beforeDestroy() {
    this.$eventBus.$off('filterable-select-fix', this.onFilterableSelectFix);
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.elSelect) {
        const elSelect = this.$refs.elSelect;
        const reference = elSelect.$refs.reference;
        const element = reference.$refs.input;
        if (this.isDynamic) {
          this.addShadow();
          this.adjustSize();
        }
        element.addEventListener('input', (event) => {
          if (reference.isComposing && this.filterable) {
            const compositionEvent = {
              type: 'compositionend',
              target: {
                value: event.target.value,
              },
            };
            reference.$emit('compositionend', compositionEvent);
            reference.$emit('input', event.target.value);
            this.$nextTick(reference.setNativeInputValue);
          }
        });
      }
    });
  },

  methods: {
    getElements() {
      const input = this.$refs.elSelect.$el.querySelector('.el-input__inner');
      const span = this.$refs.shadowSpan;
      return { input, span };
    },
    addShadow(useMinWidth = false) {
      const { input, span } = this.getElements();
      if (useMinWidth) {
        span.style.minWidth = `${input.getBoundingClientRect().width}px`;
      }
    },
    adjustSize() {
      this.$nextTick(() => {
        const { input, span } = this.getElements();
        const css = window.getComputedStyle(input, null);

        span.style.font = css.font;
        span.style.padding = css.padding;
        span.style.border = css.border;
        span.textContent = input.value;
        input.style.width = `${span.getBoundingClientRect().width}px`;
      });
    },
    focus() {
      this.focused = true;
      this.$emit('focus', event);
    },
    blur() {
      this.focused = false;
    },
    change(value) {
      this.$emit('change', value);
    },
    handleVisibleChange(value) {
      if (this.filterable && !value) {
        this.$refs.elSelect.blur();
      }

      if (value) {
        this.$eventBus.$emit('filterable-select-fix', this._uid);
      }
    },
    onFilterableSelectFix(uid) {
      if (this.filterable) {
        if (uid !== this._uid) {
          this.$refs.elSelect.blur();
        }
      }
    },
  },
};
</script>

<style lang="scss">
.resizeable-shadow {
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
  text-transform: capitalize;
  left: -99999px;
  top: -99999px;
}
.ui-select {
  display: flex;
  flex-direction: column;
  &__dropdown {
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-border);
    background-color: var(--color-background-fon);
    min-width: 150px !important;
    .popper__arrow {
      border-bottom-color: var(--color-border) !important;
    }
    .el-select-dropdown {
      &__list {
        padding: 8px;
        margin: 0;
      }
      &__item {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        padding: 8px;
        height: auto;
        border-radius: 8px;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        color: var(--color-text);
        &.selected,
        &.hover,
        &:hover {
          background-color: #f5f7fa;
        }
      }
    }
  }
  &__label-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text);
    line-height: normal;
    margin-bottom: 6px;
  }
  &__wrapper {
    display: inline-flex;
    align-items: center;
    border: 1px solid var(--color-border);
    background-image: linear-gradient(180deg, #fff, #f6f6f6);
    transition: all 0.2s;
    border-radius: 8px;

    .el-input__inner {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-text);
      line-height: normal;
      padding-left: 12px;
      border: none;
      border-radius: 8px;
      background-color: transparent;
      height: 32px;
      text-transform: capitalize;
    }
    .el-input--prefix {
      .el-input__inner {
        padding-left: 40px;
      }
    }
    .el-input__prefix {
      left: 16px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .el-input.is-disabled .el-input__inner {
      background-color: transparent;
      border-color: transparent;
    }
  }
  &__element {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 10%;
    max-width: 100%;
    flex: 10 1 auto;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;

    .el-icon-arrow-up {
      font-weight: 600;
      font-family: 'Font Awesome 6 Pro' !important;
      font-size: 12px;
      line-height: 1.33;
      color: var(--color-dark3);
      &:before {
        content: '\f077';
      }
    }
  }
  &_focused & {
    &__wrapper {
      border-color: var(--color-primary);
    }
  }
  &_error & {
    &__wrapper {
      border-color: var(--color-error);
    }
  }
  &_disabled & {
    &__wrapper {
      opacity: 0.5;
      cursor: not-allowed;
      filter: grayscale(1);
    }
  }
  &__no-data {
    padding: 8px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    color: var(--color-text);
    font-size: 12px;
  }
}
.el-scrollbar__wrap {
  max-height: 40vh;
  margin: 0 !important;
}
</style>
