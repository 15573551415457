<template>
  <div id="app" class="app">
    <Notify />
    <TechPage v-if='!isAvailable' />
    <PopupManager ref="popupManager" />
    <router-view v-if="!(isLoading || isLoadingGeo) && locationAllow && isAvailable" />
    <Location v-if="!locationAllow && !isLoadingGeo && isAvailable" />
    <Loader :is-loading="(isLoading || isLoadingGeo) && isAvailable"></Loader>
  </div>
</template>

<script>
import PopupManager from '@/components/PopupManager.vue';
import Notify from '@/components/Notify';
import i18n, { loadLanguageAsync } from '@/i18n';
import Location from '@/components/Location';
import Loader from '@/components/loaders/Loader.vue';
import geoMixin from '@/helpers/geoMixin.js';
import { mapGetters } from 'vuex';
import TechPage from '@/components/TechPage.vue';

export default {
  name: 'App',
  components: {
    TechPage,
    PopupManager,
    Notify,
    Location,
    Loader,
  },
  mixins: [geoMixin],

  computed: {
    ...mapGetters('misc', ['locationStatus', 'isLoading']),
    ...mapGetters('auth', ['user', 'isAuthenticated']),
    ...mapGetters('settings', ['settings']),
    locationAllow() {
      return this.locationStatus === 1;
    },
    isAvailable() {
      const val = this.settings?.is_off_maintenance || 0;
      return !val;
    },
    metaTitle() {
      const title = this.$route.meta.title;
      return title ? `${this.$t(`${title}`)} | BW MobCash` : 'BW MobCash';
    },
  },

  watch: {
    metaTitle: {
      immediate: true,
      handler(value) {
        document.title = value;
        this.getLocation();
      },
    },
    async '$i18n.locale'(value) {
      await loadLanguageAsync(value, true);
      await this.$store.dispatch('misc/miscInitDictionaries');
    },
  },

  async created() {
    try {
      await this.getLocalStorageLanguage();
      await this.$store.dispatch('misc/miscIsLoading', true);
      const currentTimezone = localStorage.getItem('select-timezone')
        ? localStorage.getItem('select-timezone')
        : this.$dayjs.tz.guess();
      const promises = Promise.all([
        this.getUserRequests(),
        loadLanguageAsync(i18n.locale, true),
        this.$store.dispatch('misc/miscInitDictionaries'),
        this.$store.commit('settings/settingsTimezones', currentTimezone),
      ]);
      await promises;
      await this.getMonthsList();
    } finally {
      this.$store.dispatch('misc/miscIsLoading', false);
      this.getLocation();
    }
  },
  mounted() {
    window.addEventListener('resize', this.$config.detectSize);
    this.$eventBus.$on('logout-event', this.logout);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$config.detectSize);
    this.$eventBus.off('logout-event', this.logout);
  },
  methods: {
    getMonthsList() {
      const monthList = [];
      const monthListIndex = {};
      const currentMonth = this.$dayjs().tz('UTC');

      for (let i = 0; i < 6; i++) {
        const month = currentMonth.subtract(i, 'month');
        const monthCount = month.month();
        const el = {
          label: this.$t(`months.${monthCount}`),
          value: monthCount,
          from: month.startOf('month').unix(),
          to: month.endOf('month').unix(),
        };
        monthList.push(el);
      }

      monthList.forEach((item, index) => {
        monthListIndex[item['value']] = {
          ...item,
          index,
        };
      });
      this.$store.commit('misc/initListOfMonth', {
        monthList: monthList,
        monthListIndex: monthListIndex,
      });
    },

    getLocalStorageLanguage() {
      const localStorageLanguage = localStorage.getItem('language');
      if (localStorageLanguage === null) {
        localStorage.setItem('language', 'en');
        this.$i18n.locale = 'en';
      } else {
        this.$i18n.locale = localStorageLanguage;
      }
    },

    async getUserRequests() {
      await this.$store.dispatch('auth/authUserSetup');
    },

    async logout() {
      if (this.isAuthenticated) {
        await this.$store.dispatch('auth/authLogout', true);
      }
      await this.$router.push('/auth/login');
    },
  },
};
</script>

<style lang="scss">
* {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

:active,
:focus,
:hover {
  outline: none;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
//html {
//  min-height: 100vh;
//}
body {
  //overscroll-behavior-y: contain;
  //touch-action: manipulation;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  //min-height: 100vh;
}

.app {
  min-height: calc(var(--vh) * 100px);
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--color-text);
}

a {
  text-decoration: none;
  color: var(--color-terms);
}
.no-scroll {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.element-disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
  color: var(--color-text-disable) !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-placeholder) !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-placeholder) !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-placeholder) !important;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  opacity: 1; /* Firefox */
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>
