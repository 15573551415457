<template>
  <div class="auth-layout">
    <div class="auth-layout__header">
      <UiLogo />
    </div>
    <div class="auth-layout__content">
      <div class="tech">
        <UiIcon
          :size="64"
          name="screwdriver-wrench"
          color="#408ce4"
          lib="fa"
          substyle="fas"
          class="mb-md"
        />
        <div class="tech__text">
          Maintenance is under way. Service will be available soon.
        </div>
        <UiButton
          class="mt-lg"
          icon="sync-alt"
          type="alter"
          icon-color="fff"
          :icon-size="16"
          @click="$router.go(0)"
        >
          Refresh
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TechPage',
};
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #303634;
  &__header {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    img {
      max-width: 104px;
      margin: 0 auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
    border-radius: 16px 16px 0 0;
    background-color: var(--color-background-content);
  }
}
.tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &__text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75;
    color: var(--color-text);
    text-align: center;
  }
}
</style>
