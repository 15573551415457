<template>
  <div class="auth-layout">
    <div class="auth-layout__header">
      <UiLogo />
    </div>
    <div class="auth-layout__content">
      <div class="flex align-middle justify-between mb-md">
        <div class="auth-layout__content-title"></div>
        <UiSelect
          :value="currentLang"
          :list="languages"
          option-value-param="code"
          option-label-param="code"
          option-name-param="name"
          is-item-ico
          is-list-item-ico
          is-icons-dictionary="languagesIndex"
          is-dynamic
          @change="changeLang"
        />
      </div>
      <div class="location">
        <UiIcon
          :size="64"
          :name="item.icon"
          :color="item.color"
          lib="fa"
          substyle="fas"
          class="mb-md"
        />
        <div class="location__text">
          {{ item.text }}
        </div>
        <div v-if="locationStatus === 2" class="location__info">
          <div
            class="location__info-item"
            v-html="
              $t('location.info.currentPosition', {
                userLat: this.coords.geo_lat,
                userLong: this.coords.geo_lon,
              })
            "
          />
          <div
            class="location__info-item"
            v-html="
              $t('location.info.initialPosition', {
                pointLat: this.user.geo_lat,
                pointLong: this.user.geo_lon,
              })
            "
          />
          <div
            class="location__info-item"
            v-html="$t('location.info.area', { area: area })"
          />
          <div
            class="location__info-item"
            v-html="$t('location.info.range', { distance: pDistance })"
          />
          <div class="location__buttons">
            <UiButton class="mt-lg" icon="copy" :icon-size="16" @click="copy">
              {{ $t('buttons.copy') }}
            </UiButton>
            <UiButton
              class="mt-lg"
              icon="sync-alt"
              type="alter"
              icon-color="fff"
              :icon-size="16"
              @click="$router.go(0)"
            >
              {{ $t('buttons.refresh') }}
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import geoMixin from '@/helpers/geoMixin';

export default {
  name: 'Location',
  mixins: [geoMixin],

  computed: {
    ...mapGetters('misc', [
      'locationStatus',
      'languages',
      'languagesIndex',
      'coords',
      'distance',
    ]),
    item() {
      return this.statusSettings[this.locationStatus];
    },
    area() {
      return this.$options.filters.formatNoDot(this.user.geo_area);
    },
    pDistance() {
      return this.$options.filters.formatNoDot(this.distance);
    },
    statusSettings() {
      return {
        0: {
          color: '#408ce4',
          icon: 'location-arrow',
          text: this.$t('location.blockedText'),
        },
        2: {
          color: '#ff5c70',
          icon: 'location-dot',
          text: this.$t('location.outText'),
        },
      };
    },
    currentLang() {
      return this.$i18n.locale;
    },
    flagIcon() {
      return this.currentLang !== ''
        ? this.languagesIndex[this.currentLang]?.image
        : '';
    },
  },
  methods: {
    changeLang(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
    },
    copy() {
      const copyText = this.$t('location.copyLocation', {
        userLat: this.coords.geo_lat,
        userLong: this.coords.geo_lon,
        pointLat: this.user.geo_lat,
        pointLong: this.user.geo_lon,
        area: this.area,
        distance: this.pDistance,
      });
      console.log(copyText);
      const t = document.createElement('textarea');
      t.contentEditable = true;
      t.readOnly = true;
      t.style.position = 'absolute';
      t.style.left = '-9999px';
      document.body.appendChild(t);
      t.value = copyText;
      t.select();
      t.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
        this.$eventBus.$emit('notify-event', {
          type: 'success',
          text: this.$t('profile.copied'),
        });
      } catch (e) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: e.message,
        });
      }
      t.remove();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #303634;
  &__header {
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
    border-radius: 16px 16px 0 0;
    background-color: var(--color-background-content);
  }
}
.location {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  &__text {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.75;
    color: var(--color-text);
    text-align: center;
  }
  &__info {
    text-align: center;
    margin-top: 16px;
    font-size: 14px;
    line-height: 24px;
    strong {
      font-weight: 700;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ui-button {
      padding: 8px 16px;
      display: inline-flex;
    }
  }
}
</style>
