export default {
  postPaymentsConfirm(payload) {
    return this.baseApi.post('/payments/confirm', payload);
  },
  getPaymentsStats(params) {
    return this.baseApi.get('/payments/stats', { params });
  },
  getPaymentsHistory(params) {
    return this.baseApi.get('/payments/history', { params });
  },
};
