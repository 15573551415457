import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

export default {
  methods: {
    async getFingerprint() {
      const options = {
        preprocessor: (key, value) => {
          if (key === 'userAgent') {
            const parser = new UAParser(value);
            return (
              parser.getOS().name +
              ' ' +
              parser.getBrowser().name +
              ' ' +
              JSON.stringify(parser.getDevice())
            );
          }
          return value;
        },
        excludes: {
          fontsFlash: true,
          availableScreenResolution: true,
          screenResolution: true,
        },
      };
      return Fingerprint2.getPromise(options).then((components) => {
        let values = components.map((component) => {
          return component.value;
        });
        return Fingerprint2.x64hash128(values.join(''), 31);
      });
    },
  },
};
