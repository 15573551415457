<template>
  <div class="profit">
    <UiSelect
      :value.sync="period"
      :list="monthList"
      option-value-param="value"
      option-label-param="label"
      option-name-param="label"
      prepend-icon="calendar"
      popper-class="dashboard__popper"
      @change="getPaymentsStats"
    />
    <highcharts v-if="!hideChar" :options="pieOptions" />
    <div class="profit__info mt-lg">
      <div class="profit__item">
        <div class="profit__name">{{ $t('dashboard.profit.total') }}</div>
        <div class="profit__value">
          {{ totalProfit | formatMoney }} {{ currencyCode }}
        </div>
      </div>
      <div class="profit__item">
        <div class="profit__name">
          {{ $t('dashboard.profit.deposit', { percent: percentDeposit }) }}
        </div>
        <div class="profit__value">
          {{ profitData.deposit_fee | formatMoney }} {{ currencyCode }}
        </div>
      </div>
      <div class="profit__item">
        <div class="profit__name">
          {{ $t('dashboard.profit.payout', { percent: percentWithdraw }) }}
        </div>
        <div class="profit__value">
          {{ profitData.withdraw_fee | formatMoney }} {{ currencyCode }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Profit',
  props: {
    hideChar: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      period: 0,
      profitData: {
        deposit_fee: 0,
        withdraw_fee: 0,
        deposit_sum: 0,
        withdraw_sum: 0,
      },
      pieOptions: {
        colors: ['#20815e', '#ffce06'],
        title: {
          text: undefined,
        },
        tooltip: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
          height: '280',
          style: {
            fontFamily: '"Open Sans", sans-serif',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            enableMouseTracking: false,
          },
        },
        series: [
          {
            innerSize: '80%',
            data: [
              [this.$t('chart.deposits'), 1],
              [this.$t('chart.withdrawals'), 1],
            ],
          },
        ],
      },

      hideStatuses: ['pending', 'rejected'],
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'currencyCode']),
    ...mapGetters('misc', ['monthList', 'monthListIndex']),
    canRequest() {
      return !this.hideStatuses.includes(this.user.status);
    },
    totalProfit() {
      return this.profitData.deposit_fee + this.profitData.withdraw_fee;
    },
    percentDeposit() {
      const value = (this.profitData.deposit_fee / this.totalProfit) * 100;
      return this.$options.filters.formatPercent(value);
    },
    percentWithdraw() {
      const value = (this.profitData.withdraw_fee / this.totalProfit) * 100;
      return this.$options.filters.formatPercent(value);
    },
  },

  created() {
    this.period = this.$dayjs().tz('UTC').month();
  },

  mounted() {
    this.getPaymentsStats();
  },

  methods: {
    async getPaymentsStats() {
      if (this.canRequest) {
        try {
          const period = this.monthListIndex[this.period];
          const response = await this.$api.getPaymentsStats({
            from: period.from,
            to: period.to,
          });
          this.profitData = response.data.payload.stats;
          this.$set(
            this.pieOptions.series[0].data,
            0,
            this.profitData.deposit_sum || 1
          );
          this.$set(
            this.pieOptions.series[0].data,
            1,
            this.profitData.withdraw_sum || 1
          );
        } catch (error) {
          this.errors = error.data.errors || {};
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profit {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background-color: var(--color-background-fon);
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: var(--color-text);
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__value {
    font-weight: 700;
  }
}
</style>

<style lang="scss">
.ui-select__dropdown.dashboard {
  &__popper {
    min-width: auto !important;
    width: calc(100% - 32px);
  }
}
.el-popover {
  &.balance__help {
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: none;
  }
  .balance__help {
    &-content {
      font-size: 12px;
      line-height: 2;
      color: #fff;
      p {
        display: block;
        margin-bottom: 12px;
        word-break: break-word;
        span {
          font-weight: 700;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.el-popper[x-placement^='bottom'].balance__help .popper__arrow::after {
  border-bottom-color: rgba(0, 0, 0, 0.9) !important;
}
</style>
