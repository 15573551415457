<template>
  <div class="statistics">
    <UiTitle> {{ $t('statistics.title') }} </UiTitle>
    <div class="p-lg">
      <Profit hide-char />
    </div>
  </div>
</template>

<script>
import Profit from '@/components/Profit';
export default {
  name: 'Statistics',
  components: {
    Profit,
  },
  data() {
    return {};
  },
  computed: {
    hasBlockStatus() {
      const userStatus = this.$store.state?.auth?.user?.status || '';
      const stopStatusList = this.$store.state.misc.stopStatusList;
      return stopStatusList.includes(userStatus);
    },
  },

  created() {
    if (this.hasBlockStatus) {
      this.$router.push('/dashboard');
    }
  },
};
</script>
