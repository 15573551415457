<template>
  <div class="history">
    <UiTitle> {{ $t('history.title') }} </UiTitle>
    <div class="history__wrapper">
      <UiTab class="mb-md" :current-tab.sync="filter" :items="tabs"></UiTab>
      <UiSelect
        :value.sync="period"
        :list="monthList"
        class="mb-md"
        option-value-param="value"
        option-label-param="label"
        option-name-param="label"
        prepend-icon="calendar"
        popper-class="dashboard__popper"
        @change="getPaymentsHistory(true, true)"
      />
      <template v-if="isLoading && isLoadingFirst">
        <div class="history__load">
          <Spinner />
        </div>
      </template>
      <template v-else>
        <UiInfiniteScroll
          v-if="historyList.length"
          :bottom="300"
          @reach-bottom="getPaymentsHistory()"
        >
          <HistoryItem
            v-for="item in historyList"
            :key="item.id"
            :item="item"
          />
        </UiInfiniteScroll>
        <div v-else class="history__empty">
          <UiIcon
            :size="32"
            :color="$config.cp.placeholder"
            name="list"
            lib="fa"
            substyle="fas"
            class="mb-lg"
          />
          <div class="bet-slip__empty-title">
            {{ $t('history.empty') }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import HistoryItem from '@/views/History/components/HistoryItem.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'History',
  components: {
    HistoryItem,
  },
  data() {
    return {
      tabs: [
        {
          name: this.$t('history.tabs.all'),
          value: 'all',
        },
        {
          name: this.$t('history.tabs.deposit'),
          value: 'deposit',
        },
        {
          name: this.$t('history.tabs.withdraw'),
          value: 'withdraw',
        },
      ],
      filter: 'all',
      period: this.$dayjs().tz('UTC').month(),
      historyList: [],
      scrollIsAvailable: true,
      isLoading: false,
      isLoadingFirst: true,
      limit: 11,
      offset: 0,
    };
  },
  computed: {
    ...mapGetters('misc', ['monthList', 'monthListIndex']),
    hasBlockStatus() {
      const userStatus = this.$store.state?.auth?.user?.status || '';
      const stopStatusList = this.$store.state.misc.stopStatusList;
      return stopStatusList.includes(userStatus);
    },
  },

  watch: {
    filter: {
      immediate: true,
      async handler() {
        await this.getPaymentsHistory(true, true);
      },
    },
  },

  created() {
    if (this.hasBlockStatus) {
      this.$router.push('/dashboard');
    }
  },

  mounted() {
    if (!this.hasBlockStatus) {
      this.getPaymentsHistory();
    }
  },

  methods: {
    async getPaymentsHistory(filter = false, force = false) {
      this.isLoading = true;
      if (this.scrollIsAvailable || force) {
        this.scrollIsAvailable = false;
        if (filter) {
          this.isLoadingFirst = true;
          this.historyList = [];
        }
        const period = this.monthListIndex[this.period];
        console.log(this.period, 'this.period');
        console.log(this.monthListIndex, 'monthListIndex');
        console.log(period, 'current period');
        const params = {
          from: period.from,
          to: period.to,
          limit: this.limit,
          offset: this.offset,
        };
        if (this.filter !== 'all') {
          params.type = this.filter;
        }
        try {
          const response = await this.$api.getPaymentsHistory({ ...params });
          const data = response.data.payload.list;
          this.historyList.push(...data);
          if (data.length === this.limit) {
            this.scrollIsAvailable = true;
            this.offset += 10;
          }
          this.isLoading = false;
          this.isLoadingFirst = false;
        } catch (error) {
          this.errors = error.data.errors || {};
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }
  &__empty {
    color: var(--color-placeholder);
    font-size: 14px;
  }
  &__empty,
  &__load {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
