var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-layout"},[_c('div',{staticClass:"auth-layout__header"},[_c('UiLogo')],1),_c('div',{staticClass:"auth-layout__content"},[_c('div',{staticClass:"flex align-middle justify-between mb-md"},[_c('div',{staticClass:"auth-layout__content-title"}),_c('UiSelect',{attrs:{"value":_vm.currentLang,"list":_vm.languages,"option-value-param":"code","option-label-param":"code","option-name-param":"name","is-item-ico":"","is-list-item-ico":"","is-icons-dictionary":"languagesIndex","is-dynamic":""},on:{"change":_vm.changeLang}})],1),_c('div',{staticClass:"location"},[_c('UiIcon',{staticClass:"mb-md",attrs:{"size":64,"name":_vm.item.icon,"color":_vm.item.color,"lib":"fa","substyle":"fas"}}),_c('div',{staticClass:"location__text"},[_vm._v(" "+_vm._s(_vm.item.text)+" ")]),(_vm.locationStatus === 2)?_c('div',{staticClass:"location__info"},[_c('div',{staticClass:"location__info-item",domProps:{"innerHTML":_vm._s(
            _vm.$t('location.info.currentPosition', {
              userLat: this.coords.geo_lat,
              userLong: this.coords.geo_lon,
            })
          )}}),_c('div',{staticClass:"location__info-item",domProps:{"innerHTML":_vm._s(
            _vm.$t('location.info.initialPosition', {
              pointLat: this.user.geo_lat,
              pointLong: this.user.geo_lon,
            })
          )}}),_c('div',{staticClass:"location__info-item",domProps:{"innerHTML":_vm._s(_vm.$t('location.info.area', { area: _vm.area }))}}),_c('div',{staticClass:"location__info-item",domProps:{"innerHTML":_vm._s(_vm.$t('location.info.range', { distance: _vm.pDistance }))}}),_c('div',{staticClass:"location__buttons"},[_c('UiButton',{staticClass:"mt-lg",attrs:{"icon":"copy","icon-size":16},on:{"click":_vm.copy}},[_vm._v(" "+_vm._s(_vm.$t('buttons.copy'))+" ")]),_c('UiButton',{staticClass:"mt-lg",attrs:{"icon":"sync-alt","type":"alter","icon-color":"fff","icon-size":16},on:{"click":function($event){return _vm.$router.go(0)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.refresh'))+" ")])],1)]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }