import Vue from 'vue';
import Router from 'vue-router';

import Auth from './modules/auth';

import Layout from '@/components/layout/Layout';

import Dashboard from '@/views/Dashboard/Dashboard';
import History from '@/views/History/History';
import Statistics from '@/views/Statistics/Statistics';
import Profile from '@/views/Profile/Profile';
import AccountDeposit from '@/views/AccountDeposit/AccountDeposit';

import multiguard from 'vue-router-multiguard';
import authGuard from '@/router/guards/authGuard.js';

Vue.use(Router);

const RouterInstance = new Router({
  routes: [
    ...Auth,
    {
      path: '',
      name: '',
      component: Layout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            title: 'dashboard.title',
            source: 'dashboard',
          },
          beforeEnter: multiguard([authGuard(true, '/auth')]),
        },
        {
          path: '/history',
          name: 'history',
          component: History,
          meta: {
            title: 'history.title',
            source: 'history',
          },
          beforeEnter: multiguard([authGuard(true, '/auth')]),
        },
        {
          path: '/statistics',
          name: 'statistics',
          component: Statistics,
          meta: {
            title: 'statistics.title',
            source: 'statistics',
          },
          beforeEnter: multiguard([authGuard(true, '/auth')]),
        },
        {
          path: '/account-deposit',
          name: 'account-deposit',
          component: AccountDeposit,
          meta: {
            title: 'accountDeposit.title',
            source: 'account-deposit',
          },
          beforeEnter: multiguard([authGuard(true, '/auth')]),
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile,
          meta: {
            title: 'profile.title',
            source: 'profile',
          },
          beforeEnter: multiguard([authGuard(true, '/auth')]),
        },
        {
          path: '',
          redirect: 'dashboard',
        },
        {
          path: '*',
          redirect: 'dashboard',
        },
      ],
    },
  ],
  mode: 'history',
});

export default RouterInstance;
