<template>
  <div class="account-deposit">
    <UiTitle back-route="dashboard">
      {{ $t('accountDeposit.title') }}
    </UiTitle>
    <div v-if="!isResponsePage" class="p-lg">
      <div class="account-deposit__title">
        {{ $t('accountDeposit.chooseWay') }}
      </div>
      <div class="account-deposit__type mx-rsm">
        <div
          v-for="wallet in wallets"
          :key="wallet.type"
          :class="[
            'account-deposit__type-item mx-sm ',
            {
              'account-deposit__type-item_active': currentType === wallet.type,
              'w-1/2': wallets.length > 1,
              'w-full': wallets.length === 1,
            },
          ]"
          @click="changeType(wallet.type)"
        >
          <img
            class="mb-2xs"
            :src="getImgUrl(wallet.type)"
            :alt="wallet.type"
          />
          <div class="flex">
            {{ wallet.name }}
            <template v-if="wallet.offset_percent > 0">{{ wallet.offset_percent | formatPercent }}</template>
          </div>
        </div>
      </div>
      <Tron
        v-if="currentType === 'tron'"
        :info="info"
        :is-demo="isDemo"
        :channel="channel"
      />
      <PerfectMoney
        v-if="currentType === 'perfect-money'"
        :info="info"
        :is-demo="isDemo"
        :channel="channel"
      />
    </div>
    <div class="account-deposit__response" v-else>
      <UiIcon
        :size="48"
        :name="depositStatus[responseType].icon"
        :color="depositStatus[responseType].color"
        lib="fa"
        substyle="fas"
        class="mb-lg"
      />
      <div class="account-deposit__response-title">
        {{ $t(`accountDeposit.response.${responseType}`) }}
      </div>
      <UiButton class="w-full" @click="responseBack">
        {{ $t('buttons.gotit') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Tron from '@/views/AccountDeposit/AccountDepositTypes/Tron.vue';
import PerfectMoney from '@/views/AccountDeposit/AccountDepositTypes/PerfectMoney.vue';

export default {
  name: 'AccountDeposit',

  components: {
    Tron,
    PerfectMoney,
  },

  data() {
    return {
      isResponsePage: false,
      responseType: '',
      wallets: null,
      currentType: '',
      depositStatus: {
        success: {
          icon: 'check-circle',
          color: this.$config.cpRaw.success,
        },
        decline: {
          icon: 'times-circle',
          color: this.$config.cpRaw.error,
        },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),

    channel() {
      return this.user.channel;
    },
    isDemo() {
      return !!this.user.is_demo;
    },

    info() {
      const currentInfo = this.wallets.filter(
        (item) => item.type === this.currentType
      );
      return {
        ...currentInfo[0],
        isDemo: this.isDemo,
        channel: this.channel,
      };
    },
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const isResponse = urlParams.get('PAYMENT_BATCH_NUM');
    if (isResponse) {
      this.responseType = +isResponse !== 0 ? 'success' : 'decline';
      this.isResponsePage = true;
    } else {
      this.getWallets();
    }
  },

  methods: {
    async getWallets() {
      try {
        const response = await this.$api.getUserWallet();
        this.wallets = this.$_.cloneDeep(response.data.payload.wallets);
        this.currentType = this.wallets[0].type;
      } catch (error) {
        console.log(error);
      }
    },
    getImgUrl(type) {
      const images = require.context('../../assets/images/methodTypes/');
      return images('./' + type + '.svg');
    },
    changeType(type) {
      this.currentType = type;
    },
    responseBack() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style lang="scss">
.account-deposit {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: var(--color-text);
    margin-bottom: 16px;
  }
  &__type {
    display: flex;
    align-items: stretch;

    margin-bottom: 16px;
    &-item {
      padding: 8px;
      border-radius: 8px;
      border: solid 2px var(--color-background-fon);
      background-color: var(--color-background-fon);

      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 12px;
      font-weight: 700;
      line-height: 1.67;
      color: var(--color-text);
      &_active {
        border-color: var(--color-primary);
      }
    }
  }
  &__panel {
    padding: 12px 16px;
    border-radius: 8px;
    background-color: var(--color-background-fon);
    margin-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 14px;
      line-height: 1.71;
      color: #2d2d2d;
    }
    &-info {
      font-size: 12px;
      color: var(--color-text);
      min-width: 0;
      padding-right: 8px;
    }
    &-title {
      font-weight: 600;
      line-height: 1.33;
      margin-bottom: 6px;
    }
    &-value {
      font-weight: 700;
      line-height: 1.67;
      flex-shrink: 1;
      &.copy {
        color: var(--color-alter);
      }
      &_ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    &_no-icon {
      .account-deposit__panel {
        &-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-right: 0;
        }
        &-title {
          margin-bottom: 0;
        }
      }
    }
  }
  &__calc {
    &-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
    }
    &-value {
      max-width: 50%;
    }
  }
  &__response {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-height: 200px;
    &-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 32px;
    }
  }
  p {
    font-size: 14px;
    line-height: 1.71;
    color: #2d2d2d;
  }
}
</style>
