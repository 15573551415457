<template>
  <div class="dashboard">
    <div class="wrapper">
      <UiTitle>
        {{ $t('dashboard.title') }}
        <Status slot="status" />
      </UiTitle>
      <div class="p-lg">
        <div v-if="showNote" class="warning">
          <UiIcon
            class="mr-sm"
            :size="18"
            :color="$config.cpRaw.placeholder"
            name="exclamation-circle"
            lib="fa"
            substyle="far"
          />
          {{ user.note }}
        </div>
        <div v-if="isVisibleBlock" class="balance">
          <div class="balance__info">
            <div class="balance__title">
              <UiIcon
                class="mr-sm"
                :size="18"
                :color="$config.cpRaw.placeholder"
                name="wallet"
                lib="fa"
                substyle="far"
              />
              {{ $t('dashboard.balance.title') }}
            </div>
            <div class="balance__value">
              {{ user.channel.balance | formatMoney }} {{ currencyCode }}
              <el-popover
                width="320"
                placement="bottom"
                trigger="click"
                popper-class="balance__help"
              >
                <UiIcon
                  slot="reference"
                  class="ml-2xs"
                  :size="18"
                  :color="$config.cpRaw.alter"
                  name="question-circle"
                  lib="fa"
                  substyle="fas"
                />
                <div
                  class="balance__help-content"
                  v-html="$t('dashboard.balance.help')"
                />
              </el-popover>
            </div>
          </div>
          <div class="flex">
            <div v-if="!this.user.is_demo" class="mr-lg" @click="walletCheck">
              <UiIcon
                :size="32"
                :color="$config.cpRaw.alter"
                name="sync"
                lib="fa"
                substyle="far"
              />
            </div>
            <router-link to="account-deposit">
              <UiIcon
                :size="32"
                :color="$config.cpRaw.alter"
                name="plus-circle"
                lib="fa"
                substyle="far"
              />
            </router-link>
          </div>
        </div>
        <Profit />
      </div>
    </div>
    <div v-if="isVisibleBlock" class="actions">
      <UiButton
        class="actions__item mr-lg"
        type="alter"
        :icon-color="$config.cpRaw['text-alter-button']"
        icon="plus-circle"
        @click="openPopupHandler('TopUp')"
      >
        {{ $t('buttons.topup') }}
      </UiButton>
      <UiButton
        class="actions__item"
        icon="down"
        @click="openPopupHandler('Withdraw')"
      >
        {{ $t('buttons.withdraw') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import Profit from '@/components/Profit';
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
    Profit,
  },

  data() {
    return {
      hideStatuses: ['pending', 'rejected', 'suspended'],
      notesStatuses: ['rejected', 'suspended'],
    };
  },

  computed: {
    ...mapGetters('auth', ['user', 'currencyCode']),
    isVisibleBlock() {
      return !this.hideStatuses.includes(this.user.status);
    },
    showNote() {
      return this.notesStatuses.includes(this.user.status);
    },
  },

  methods: {
    walletCheck() {
      this.$store.dispatch('auth/updateUser');
    },

    async openPopupHandler(popup) {
      this.$eventBus.$emit('open-popup', popup);
      // if (popup === 'AccountDeposit' && !this.isAccountDepositOpen) {
      //   try {
      //     this.isAccountDepositOpen = true;
      //     const response = await this.$api.getUserWallet();
      //     await this.$eventBus.$emit('open-popup', 'AccountDeposit', {
      //       data: {
      //         info: this.user.wallet,
      //         channel: this.user.channel,
      //         isDemo: !!this.user.is_demo,
      //         currentRate: response.data.payload.rate,
      //       },
      //     });
      //     this.isAccountDepositOpen = false;
      //   } catch (error) {
      //     this.isAccountDepositOpen = false;
      //   }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-background-fon);
    margin-bottom: 12px;
    font-weight: 600;
    &__title {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 12px;
    }
    &__value {
      font-size: 20px;
      line-height: 1;
    }
  }
  .actions {
    display: flex;

    position: sticky;
    bottom: 67px;

    padding: 16px;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--color-background-fon);
    &__item {
      width: 50%;
    }
  }
  .warning {
    display: flex;
    align-items: flex-start;
    border-radius: 8px;
    background-color: #f6e1d4;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.67;
    color: var(--color-text);
  }
}
</style>
