import helper from '@/helpers/helper';

export default {
  data() {
    return {
      amount: '',
      amountFormat: '',
    };
  },

  computed: {
    pReceive() {
      return this.$options.filters.formatMoney(
        this.amount * this.info.rate * (this.info.offset_percent / 100 + 1)
      );
    },
  },

  methods: {
    changeSum(value) {
      let pValue = value.toString().replace(/[^0-9.?]/g, '');

      if (+pValue < 0) {
        pValue = '';
      }

      this.$nextTick(() => {
        this.amount = pValue === '' ? '' : pValue;
        if (
          pValue.indexOf('.') !== -1 &&
          !helper.isEmpty(pValue.split('.')[1])
        ) {
          let pValueAfterDot;
          if (pValue.split('.')[1].length > 2) {
            pValueAfterDot = pValue.split('.')[1].substr(0, 2);
          } else {
            pValueAfterDot = pValue.split('.')[1];
          }
          this.amountFormat = `${this.$options.filters.formatMoneyNoDotNoRound(
            pValue
          )}.${pValueAfterDot}`;
          return;
        } else if (
          pValue.indexOf('.') !== -1 &&
          helper.isEmpty(pValue.split('.')[1])
        ) {
          this.amountFormat = `${this.$options.filters.formatMoneyNoDotNoRound(
            pValue
          )}.`;
          return;
        }
        this.amountFormat =
          this.$options.filters.formatMoneyNoDotNoRound(pValue);
      });
    },
    copy() {
      const t = document.createElement('input');
      t.contentEditable = true;
      t.readOnly = true;
      t.style.position = 'absolute';
      t.style.left = '-9999px';
      document.body.appendChild(t);
      t.value = this.info.wallet;
      t.select();
      t.setSelectionRange(0, 99999);
      try {
        document.execCommand('copy');
        this.$eventBus.$emit('notify-event', {
          type: 'success',
          text: this.$t('accountDeposit.copied'),
        });
      } catch (e) {
        this.$eventBus.$emit('notify-event', {
          type: 'error',
          text: e.message,
        });
      }
      t.remove();
      return false;
    },
  },
};
