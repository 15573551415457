<template>
  <Popup
    class="withdraw__popup"
    :title="$t('popups.withdraw.title')"
    icon="down"
    :is-visible.sync="isVisible"
    @close="clear"
  >
    <template slot="body">
      <template v-if="!isRequest">
        <p v-html="$t('popups.withdraw.text')" />
        <UiInput
          v-model="form.transaction_id"
          class="mb-md"
          :label="$t('popups.withdraw.form.id.label')"
          :placeholder="$t('popups.withdraw.form.id.placeholder')"
          type="text"
          :error="errors.transaction_id"
          autocomplete="off"
          scroll-to
        />
        <UiInput
          v-model="form.amount"
          class="mb-md"
          :label="$t('popups.withdraw.form.amount.label')"
          :currency="currencyCode"
          placeholder=""
          type="text"
          :error="errors.amount"
          autocomplete="off"
          scroll-to
        />
        <UiInput
          v-model="form.security_code"
          class="mb-4xl"
          :label="$t('popups.withdraw.form.code.label')"
          :placeholder="$t('popups.withdraw.form.code.placeholder')"
          type="text"
          :error="errors.security_code"
          autocomplete="off"
          scroll-to
        />
      </template>
      <template v-else>
        <RequestResultPopup :type="requestType" slot="body">
          <template slot="title">
            {{ $t(`popups.withdraw.${requestType}.title`) }}
          </template>
          <template slot="text">
            {{ $t(`popups.withdraw.${requestType}.text`) }}
          </template>
        </RequestResultPopup>
      </template>
    </template>
    <template slot="footer">
      <template v-if="!isRequest">
        <UiButton type="secondary" class="mr-lg w-1/2" @click="close">
          {{ $t('buttons.cancel') }}
        </UiButton>
        <UiButton :loading="isLoading" class="w-1/2" @click="withdrawHandler">
          {{ $t('buttons.withdraw') }}
        </UiButton>
      </template>
      <template v-else>
        <UiButton class="w-full" @click="close">
          {{ $t('buttons.gotit') }}
        </UiButton>
      </template>
    </template>
  </Popup>
</template>

<script>
import RequestResultPopup from '@/components/RequestResultPopup';
import { mapGetters } from 'vuex';
import geoMixin from '@/helpers/geoMixin';

export default {
  name: 'Withdraw',
  components: {
    RequestResultPopup,
  },
  mixins: [geoMixin],
  data() {
    return {
      form: {
        type: 'withdraw',
        transaction_id: '',
        amount: '',
        security_code: '',
      },
      requestType: 'success',
      isRequest: false,
      isLoading: false,
      isVisible: true,
      errors: {},
    };
  },

  computed: {
    ...mapGetters('auth', ['currencyCode']),
    ...mapGetters('misc', ['coords']),
  },

  methods: {
    defaultValues() {
      this.form = {
        transaction_id: '',
        amount: '',
        security_code: '',
      };
      this.errors = {};
    },

    open() {
      this.isVisible = true;
    },
    async withdrawHandler() {
      this.isLoading = true;
      await this.getLocation();
      await this.withdraw();
    },
    async withdraw() {
      this.errors = {};
      this.isLoading = true;
      try {
        const coords = {
          geo_lat: this.coords.geo_lat.toString(),
          geo_lon: this.coords.geo_lon.toString(),
        };
        this.form.amount.toNumber;
        await this.$api.postPaymentsConfirm({
          ...this.form,
          ...coords,
        });
        this.isRequest = true;
        await this.$store.dispatch('auth/authUser');
      } catch (errors) {
        console.log(errors);
        if (errors?.data?.errors?.geo_lat) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.geo_lat[0],
          });
        }
        if (errors?.data?.errors?.geo_lon) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.geo_lon[0],
          });
        }
        if (errors?.data?.errors?.geo) {
          this.$eventBus.$emit('notify-event', {
            type: 'error',
            text: errors?.data?.errors?.geo[0],
          });
        }
        this.errors = errors?.data?.errors || {};
      } finally {
        this.isLoading = false;
      }
    },

    clear() {
      this.defaultValues();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw__popup {
  p {
    font-size: 12px;
  }
}
</style>
