<template>
  <div class="auth-layout">
    <div class="auth-layout__header">
      <UiIcon :size="16" :name="linkIcon" lib="fa" substyle="fas" />
      <UiLogo />
      <router-link :to="linkPath">
        <UiIcon
          :color="$config.cp['primary']"
          :size="16"
          :name="linkIcon"
          lib="fa"
          substyle="fas"
        />
      </router-link>
    </div>
    <div class="auth-layout__content">
      <div class="flex align-middle justify-between mb-md">
        <div class="auth-layout__content-title">
          {{ title }}
        </div>
        <UiSelect
          :value="currentLang"
          :list="languages"
          option-value-param="code"
          option-label-param="code"
          option-name-param="name"
          is-item-ico
          is-list-item-ico
          is-icons-dictionary="languagesIndex"
          is-dynamic
          @change="changeLang"
        />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AuthLayout',
  computed: {
    ...mapGetters('misc', ['languages', 'languagesIndex']),
    currentLang() {
      return this.$i18n.locale;
    },
    flagIcon() {
      return this.currentLang !== ''
        ? this.languagesIndex[this.currentLang]?.image
        : '';
    },

    source() {
      return this.$route.meta.source;
    },
    linkIcon() {
      return this.source === 'login' ? 'user-plus' : 'sign-in';
    },
    linkPath() {
      return this.source === 'login' ? 'signup' : 'login';
    },
    title() {
      return this.$t(`auth.${this.source}.title`);
    },
  },
  methods: {
    changeLang(value) {
      this.$i18n.locale = value;
      localStorage.setItem('language', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #303634;
  &__header {
    padding: 12px 16px;
    display: flex;
    align-items: center;
  }
  &__divider {
    width: 20px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 16px;
    border-radius: 16px 16px 0 0;
    background-color: var(--color-background-content);
    &-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.33;
    }
  }
}
</style>
