<template>
  <notifications class="notify" group="notify">
    <template slot="body" slot-scope="props">
      <div class="notify-wrapper" :class="[`notify_${props.item.type}`]">
        <UiIcon
          :size="14"
          :name="props.item.type ? 'check-circle' : 'exclamation-circle'"
          :color="$config.cpRaw['text-alter-button']"
          lib="fa"
          substyle="fas"
          class="mr-sm"
        />
        <div class="notify-content">
          {{ props.item.text }}
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Notify',

  mounted() {
    this.$eventBus.$on('notify-event', this.sendNotify);
  },

  beforeDestroy() {
    this.$eventBus.$off('notify-event', this.sendNotify);
  },

  methods: {
    sendNotify(data) {
      this.$notify({
        group: 'notify',
        type: data.type,
        duration: 3000,
        text: data.text,
      });
    },
  },
};
</script>

<style lang="scss">
.notify {
  &-wrapper {
    padding: 12px;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
  }
  &-content {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-alter-button);
  }
  &_success {
    background-color: var(--color-success);
  }
  &_error {
    background-color: var(--color-error);
  }
}
</style>
